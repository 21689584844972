import { API_ROOT } from "./henceforthApi"

export const initLangData = async (id: string, lang: string) => {
    debugger
    let rowData = {}
        try {
            let apiRes = await fetch(`${API_ROOT}User/main-keys/${id}?language=${lang}`)
            let apiResJson = await apiRes.json()
            apiResJson?.data?.map((res: any) => {
                rowData = {
                    ...rowData,
                    [res.key]: res.value
                }

            })
            return {...rowData  }
        } catch (error) {
            return {
                redirect: {
                    destination: '/',
                    permanent: false,
                },
            }
        }
}