import profile_img1 from '../../assets/images/pages/laptop.jpg';
import profile_img from '../../assets/images/pages/defaultImage.jpg';
import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../../components/common/spinner';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/common/BreadCrumb';
import { numberWithCommas } from '../../utils/validations';
import { orderDetails } from './orderInterface';
import henceofrthEnums from '../../utils/henceofrthEnums';
import OrderStatus from '../../components/order/OrderStatus';
import { toast } from 'react-toastify';
import OrderStatusAction from '../../components/order/OrderStatusAction';
import Ratings from '../../components/common/Ratings';

const ViewOrderDetail = () => {
    const { language_data, loading, setLoading, authState, authDispatch } = React.useContext(GlobalContext)

    const match = useMatch('/order/:id')
    const location = useLocation()
    const newParam = new URLSearchParams(location.search)
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: newParam.has('transaction') ? `${language_data?.[authState.lang]?.common_all} ${language_data?.[authState.lang]?.common_transaction}` : `${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_list}`, url: newParam.has('transaction') ? '/earnings/1' : `/orders/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_deatil}`, url: ``, active: 'not-allowed' },
    ]
    const [tracking, setTracking] = React.useState("")

    const [orderD, setOrderD] = useState({} as orderDetails)
    const [showMore, setShowMore] = useState(false)
    const orderDetail = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Order.getOrderDetails(match?.params.id)).data
            setOrderD(res)
        } catch (err: any) {
            console.log(err.response.body.error_description);
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        orderDetail()
    }, [match?.params.id])

    const changeStatus = async (order_status: string) => {
        const items = {
            _id: match?.params.id,
            order_status,
            language: "ENGLISH"
        } as any
        if (orderD?.order_status === henceofrthEnums.OrderStatus.PLACED && order_status === henceofrthEnums.OrderStatus.SHIPPED) {
            if (tracking) items.tracking_link = tracking
            else return toast.warn(`${language_data?.[authState.lang]?.common_please_enter_tracking_id_or_link}  `)
        }
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure} ${language_data?.[authState.lang]?.common_questionmark}`,
            text: `${language_data?.[authState.lang]?.common_you_want_to} ${language_data?.[authState.lang]?.common_change_in} ${order_status}?`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: `${language_data?.[authState.lang]?.common_no}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes}`,

        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const apiRes = await henceforthApi.Delivery.changesStatus(items)
                    await orderDetail()

                    Swal.fire({
                        icon: 'success',
                        title: `${language_data?.[authState.lang]?.common_delivery}`,
                        text: `${language_data?.[authState.lang]?.common_your_delivery_step_has_been_changed_into} ${order_status}.`,
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false
                    } as any)
                } catch (err: any) {
                    console.log(err.response.body.error_description);
                    handleError(err, 'active', authDispatch);
                }
            }
        })
    }
    const cancelRequest = (id: string) => {
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure} ${language_data?.[authState.lang]?.common_questionmark}`,
            text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${language_data?.[authState.lang]?.common_cancel} ${language_data?.[authState.lang]?.common_this} ${language_data?.[authState.lang]?.common_request}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: `${language_data?.[authState.lang]?.common_cancel}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes} ${language_data?.[authState.lang]?.common_accept} ${language_data?.[authState.lang]?.common_it}`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    await henceforthApi.Order.editCancelOrder({
                        _id: id,
                        language: 'ENGLISH'
                    })
                    orderDetail()
                    Swal.fire({
                        icon: 'success',
                        title: `${language_data?.[authState.lang]?.common_cancellation} ${language_data?.[authState.lang]?.common_request}`,
                        text: `${language_data?.[authState.lang]?.common_you_have_accepted} ${language_data?.[authState.lang]?.common_request}.`,
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false
                    }
                    )
                } catch (err: any) {
                    handleError(err, 'active', authDispatch);
                    console.log(err.response.body.error_description);
                }
            }
        })
    }

    return (
        <Fragment>

            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            {loading ? <div className='vh-100 d-flex justify-content-center py-5'>
                <Spinner color={'text-success'} />
            </div> : <div className='page-spacing'>
                <section className='product-detail'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                {/* Title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <div className='d-flex mb-2 flex-column flex-md-row product-detail-params align-items-center justify-content-md-between'>
                                            <h5 className='mb-2 m-0 mb-md-0'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_deatil}</h5>
                                            <div className='d-sm-flex'>
                                                <div className="btn-group gap-2 me-sm-2 justify-content-center d-flex">
                                                    {/* <button type="button" className="btn btn-white btn-sm"> <i className="fa fa-download me-1"></i>Download PDF</button> */}
                                                    <Link to="invoice" className="btn btn-white btn-sm text-center" target="_blank"> <i className="fa fa-user me-1"></i>{language_data?.[authState.lang]?.common_download_seller_invoice}</Link>
                                                </div>
                                                {orderD?.order_status === "DELIVERED" ? '' :
                                                    <div className="btn-group gap-2 justify-content-center mt-2 mt-sm-0">
                                                        {orderD?.order_status === henceofrthEnums.OrderStatus.CANCELLED ?
                                                            <button type="button" className={`btn btn-white btn-sm bg-danger text-white border-danger fw-bold`} disabled>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_canceled}</button>
                                                            : orderD.cancel_requested === true ?
                                                                <button className={`btn btn-white btn-sm bg-danger text-white border-danger}`} onClick={() => cancelRequest(orderD._id)}>{language_data?.[authState.lang]?.common_accept_cancellation_request}</button>
                                                                :
                                                                <Link to={`/order/cancel/${match?.params.id}/${orderD?.order_object_id}`} className={`btn btn-white btn-sm bg-danger text-white border-danger}`} >  {language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_cancel}</Link>}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Profile  */}
                                    <div className="common-card-content">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                                    <div className="carousel-indicators">
                                                        {Array.isArray(orderD?.product_id?.images) && orderD?.product_id?.images?.map((res: string, index: number) => {
                                                            return (
                                                                <button type="button" key={index} data-bs-target='#carouselExampleIndicators' data-bs-slide-to={index} className={`${index === 0 ? "active" : ""} ${orderD?.product_id?.images.length === 1 ? "d-none" : ""}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="carousel-inner product-images">
                                                        {Array.isArray(orderD?.product_id?.images) && orderD?.product_id?.images?.map((res: string, index: number) => {
                                                            return (
                                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                                                    <img src={res ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res}` : profile_img1} className="d-block w-100" alt="img" />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">{language_data?.[authState.lang]?.common_previous}</span>
                                                    </button>
                                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">{language_data?.[authState.lang]?.common_next}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Product detail  */}
                                            <div className="col-md-7">
                                                <div className="product-detail-box">
                                                    <Link className='text-black text-decoration-none text-capitalize' to={`/product/${orderD?.product_id?._id}`}> <h2 className='fw-bolder'>{orderD?.product_id?.name ? orderD?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</h2></Link>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <h2 className='fw-semibold m-0'>&#36; {orderD?.total_price ? numberWithCommas(orderD?.total_price) : `${language_data?.[authState.lang]?.common_not_available}`}</h2><small> {language_data?.[authState.lang]?.common_including_tax}</small>
                                                    </div>
                                                    <div className="divider my-3"></div>
                                                    <div className='mb-3'>
                                                        <h4 className='fw-bolder'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_description}</h4>
                                                        <p className='fw-semibold text-capitalize'>{orderD?.product_id?.description ? `${orderD?.product_id?.description.length > 1000 ? !showMore ? `${orderD?.product_id?.description?.slice(1000)}` : orderD?.product_id?.description : orderD?.product_id?.description}` : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                        {orderD?.product_id?.description?.length > 1000 ? <p className='ReadMore' role="button" onClick={() => setShowMore(!showMore)}>{!showMore ? `${language_data?.[authState.lang]?.common_read_more}` : `${language_data?.[authState.lang]?.common_read_less}`} </p> : ''} </div>
                                                    <ul className='list-unstyled product-detail-list'>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_id}:</b><span>{orderD?.order_id ? orderD?.order_id : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}:</b><span>{orderD?.product_id?.prod_id ? orderD?.product_id?.prod_id : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_name}:</b><span>{orderD?.product_id?.name ? orderD?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}:</b><span>&#36; {orderD?.price ? numberWithCommas(orderD?.price) : `${language_data?.[authState.lang]?._0}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_quantity}:</b><span>{orderD?.quantity ? orderD?.quantity : `${language_data?.[authState.lang]?._0}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_quantity}:</b><span>{orderD?.discount_percantage ? orderD?.discount_percantage : `${language_data?.[authState.lang]?._0}`}%</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_coupon}/{language_data?.[authState.lang]?.common_discount} {language_data?.[authState.lang]?.common_price}:</b><span>{orderD?.coupon_discount ? orderD?.coupon_discount : `${language_data?.[authState.lang]?._0}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_brand}</b><span>{orderD?.product_id?.brand_id?.name ? orderD?.product_id?.brand_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_tax} {language_data?.[authState.lang]?.common_percentage}</b><span>{orderD?.tax_percentage ? orderD?.tax_percentage : `${language_data?.[authState.lang]?._0}`}%</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* User seller order-status detail  */}
                        <div className="row">
                            {/* User Detail  */}
                            <div className="col-sm-6 col-md-6 col-xl-4 mb-3">
                                <div className="common-card h-100  ">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_user} {language_data?.[authState.lang]?.common_deatil}</h5>
                                    </div>

                                    <div className="common-card-content">
                                        <div className="product-detail-box">
                                            <div className="profile-image">
                                                <img src={orderD?.user_id?.profile_pic !== null ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${orderD?.user_id?.profile_pic}` : profile_img} alt="img" className='img-fluid' />
                                            </div>
                                            {/* Profile Detail  */}
                                            <div className="profile-image my-4">
                                                <h5 className='mb-3'>{orderD?.user_id?.name ? orderD?.user_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</h5>
                                                <p className="d-flex align-items-start mb-2"><i className='fa fa-envelope me-2 fs-5'></i>{orderD?.user_id?.email ? orderD?.user_id?.email : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                <p className="d-flex align-items-start mb-2"><i className='fa fa-phone-square me-2 fs-5'></i>+{orderD?.user_id?.country_code ? orderD?.user_id?.country_code : ""} {orderD?.user_id?.phone_no ? orderD?.user_id?.phone_no : ""}</p>
                                                <p className="d-flex align-items-start"><i className='fa fa-map me-2 fs-5'></i>{orderD?.address_id?.full_address ? orderD?.address_id?.full_address : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Order Status  */}
                            <div className={`${newParam.has('transaction') ? "col-md-6 col-sm-6 col-xl-3" : "col-md-6"}`}>
                                <div className='h-100 d-flex flex-column'>
                                    {/* Delivery Status */}
                                    <div className="common-card h-100 mb-3">
                                        <div className="common-card-title d-flex justify-content-between align-align-items-center">
                                            <h5>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_status}</h5>
                                            {orderD.cancel_request_accepted || orderD.cancel_requested || orderD?.order_status === henceofrthEnums.OrderStatus.PENDING_CANCELLATION || orderD?.order_status === henceofrthEnums.OrderStatus.CANCELLED ? '' : <div className="btn-group gap-2">
                                                {orderD?.order_status === henceofrthEnums.OrderStatus.PLACED &&
                                                    <div className='form-fields-box'> <input type={'text'} className="form-control" placeholder={`${language_data?.[authState.lang]?.common_please_enter_tracking_id_or_link}`} onChange={(e) => setTracking(e.target.value)} /></div>}
                                                <OrderStatusAction  {...orderD} onClick={changeStatus} />
                                            </div>}
                                        </div>
                                        <div className="common-card-content">
                                            <div className="product-detail-box">
                                                <ul className='list-unstyled'>
                                                    {((orderD?.order_status === henceofrthEnums.OrderStatus.CANCELLED) || (orderD.cancel_request_accepted && orderD.cancel_requested)) || ((!orderD.cancel_request_accepted && !orderD.cancel_requested) && (orderD?.order_status !== henceofrthEnums.OrderStatus.PENDING_CANCELLATION)) ?
                                                        <li><p className='mb-2 fw-bold'>{language_data?.[authState.lang]?.common_delivery} {language_data?.[authState.lang]?.common_status}------</p>
                                                            <OrderStatus {...orderD} />
                                                        </li> : <li>
                                                            <div className='cancel-order-reason-box mb-3'>
                                                                <p className='fw-bold mb-1'>{language_data?.[authState.lang]?.common_cancel} {language_data?.[authState.lang]?.common_reason} ------</p>
                                                                <span className='text-white bg-success  px-2 py-half rounded-half fs-10 fw-semibold text-nowrap'>{orderD?.cancel_reason ? orderD?.cancel_reason : ''}</span>
                                                            </div>

                                                            <div className='cancel-order-reason-box mb-4'>
                                                                <p className='fw-bold mb-1'>{language_data?.[authState.lang]?.common_cancel} {language_data?.[authState.lang]?.common_description}  ------</p>
                                                                <span className='text-white bg-success  px-2 py-half rounded-half fs-10 fw-semibold text-nowrap'>{orderD?.cancel_description ? orderD?.cancel_description : ''}</span>
                                                            </div>

                                                            <button className='btn btn-danger btn-sm' onClick={() => cancelRequest(orderD._id)}>{language_data?.[authState.lang]?.common_accept}</button>
                                                        </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Order Earning */}
                            {newParam.has('transaction') ? <div className="col-xl-5">
                                <div className='h-100 d-flex flex-column'>
                                    {/* Delivery Status */}
                                    <div className="common-card h-100 mb-3">
                                        <div className="common-card-title d-flex justify-content-between align-align-items-center">
                                            <h5>{language_data?.[authState.lang]?.common_price} {language_data?.[authState.lang]?.common_breakdown}</h5>
                                        </div>
                                        <div className="common-card-content">
                                            <div className="product-detail-box">
                                                <ul className='list-unstyled'>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.actual_product_price ? numberWithCommas(orderD?.actual_product_price) : "0"}</span></li>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_discount} {language_data?.[authState.lang]?.common_price}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.actual_product_price && orderD.discount_price ? numberWithCommas(orderD?.actual_product_price - orderD.discount_price) : "0"}</span></li>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_coupon} {language_data?.[authState.lang]?.common_price}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.coupon_discount ? numberWithCommas(orderD?.coupon_discount) : "0"}</span></li>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_final} {language_data?.[authState.lang]?.common_price}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.total_price ? numberWithCommas(orderD?.total_price) : "0"}</span></li>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_earning} {language_data?.[authState.lang]?.common_price}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.total_earnings ? numberWithCommas(orderD?.total_earnings) : "0"}</span></li>
                                                    <li className="d-flex mb-2"><b className='w-50'>{language_data?.[authState.lang]?.common_platform} {language_data?.[authState.lang]?.common_fees}:</b><span className='flex-grow-1 w-75'>&#36; {orderD?.admin_commision ? numberWithCommas(orderD?.admin_commision) : "0"}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                        </div>
                        {/* Ratings  */}
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <Ratings ratingArray={orderD?.product_id?.ratings} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>}
        </Fragment>
    )
}
export default ViewOrderDetail;