import { useMatch } from 'react-router-dom'
import { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { SaveCancelButton } from '../../../components/common/Inputs';
import { toast } from 'react-toastify';
import Errormessage from '../../../components/common/errormessage';
const EditVariant = () => {
    const {language_data, authState, authDispatch } = useContext(GlobalContext);
    const match = useMatch('product/:product_id/variant/:variant_id/edit')
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.product_id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_services}`, url: ``, active: 'not-allowed' }
    ]

    const [loading, setLoading] = useState<boolean>(false);
    const [searchProduct, setSearchProduct] = useState<string>('')
    const [variantErr, setVariantErr] = useState<string>('')
    const [productList, setProductList] = useState<{ _id: string, name: string }[]>([])
    const [variant, setVariant] = useState<Array<{ _id: string, name: string }>>([])
    const [load, setLoad] = useState<boolean>(false)
    

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!variant.length) return setVariantErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_one}${language_data?.[authState.lang]?.common_variant}${language_data?.[authState.lang]?.common_for}${language_data?.[authState.lang]?.common_linking}`)
        setLoading(true)
        try {
            const apiRes = await henceforthApi.Product.editProductDetailsDynamically('',{})
            // toastMessage(apiRes.message)
            toast.success(apiRes.message)
            window.history.back();
        }
        catch (error) {
            handleError(error, 'active', authDispatch);
        } finally {
            setLoading(false)
        }
    };
    const productListing = async () => {
        setLoad(true)
        try {
            let res = (await henceforthApi.Product.getProductList(searchProduct, '', '', '')).data.data
            setProductList(res)
        } catch (err) {
            console.log("err", err);
            handleError(err, '', authDispatch)
        } finally {
            setLoad(false)
        }
    }
    useEffect(() => {
        productListing();
    }, [searchProduct])

    const product_idsHandler = (id: string, name: string, index: number) => {
        if (id && name) {
            let temp2 = variant.some((res) => res._id === id && res.name === name)
            // let temp = coupon.product_ids.filter((res) => res._id !== id && res.name !== name)
            if (temp2) return toast.warn(`${language_data?.[authState.lang]?.common_already_} ${language_data?.[authState.lang]?.common_added_}`)
            setVariant([...variant, { _id: id, name: name }])
            setSearchProduct('')
        }
        else {
            variant.splice(index, 1)
            setVariant([...variant])
        }
    }
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-detail'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-9 col-md-8 col-lg-6 col-xl-6 col-xxl-5">
                                <div className="common-card">
                                    <div className="common-card-content">
                                        <div className="form-fields-box">
                                            <label className="mb-1 form-label fw-semibold"><b>{`${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_services}`}</b> ({language_data?.[authState.lang]?.common_coupon_valid_for_selected_product})</label>
                                            <div className="position-relative">
                                                {/* <form action=""> */}
                                                <input type="search" className="form-control rounded-0 ps-4 " name="search" placeholder={`${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_your} ${language_data?.[authState.lang]?.common_product}`} value={searchProduct} onChange={({ target }: any) => { setLoad(true); setSearchProduct(target.value) }} />
                                                <span className="search-icon">
                                                    <i className="fa fa-search"></i>
                                                </span>

                                                {searchProduct && Array.isArray(productList) && !load && productList.length ? <ul className='mb-0 list-unstyled search-coupon-list'>
                                                    {productList.map((res) => <li key={res._id} onClick={() => product_idsHandler(res._id, res.name, -1)}><p>{res?.name}</p></li>)}
                                                </ul> : ''}
                                                {/* </form> */}
                                            </div>
                                            {/* coupon-list-box */}
                                            {Array.isArray(variant) && variant.length ? <div className="coupon-list-box mt-3 d-inline-flex gap-3">
                                                {variant.map((res, index) => <span className='coupon-list-badge' key={res._id} >{res.name} <i className='fa fa-close ms-1' role="button" onClick={() => product_idsHandler('', '', index)}></i></span>)}
                                            </div> : ''}
                                            <Errormessage error={variantErr} class={variantErr} />
                                        </div>
                                        <SaveCancelButton loading={loading} color="text-white" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default EditVariant;