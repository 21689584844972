import '../../assets/styles/pages.scss'
import { useMatch, useNavigate } from 'react-router-dom'
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../../components/common/spinner';
import { Fragment, useContext, useEffect, useState } from 'react';
import { documentId, formValidate, NumberValidation } from '../../utils/validations';
import BreadCrumb from '../../components/common/BreadCrumb';
import Errormessage from '../../components/common/errormessage';
import { GlobalContext, handleError } from '../../context/Provider';
import imageUpload from '../../assets/images/pages/dummy-image.jpg';
import { brandListing, dataHolder, MultipleSelect } from './productInterface';
import { SaveCancelButton } from '../../components/common/Inputs';
import Select from '../../components/common/Select';
import commonArray from '../../components/common/commonArray';
import Textarea from '../../components/common/Textarea';
import Inputs from '../../components/common/Inputs';

const CloneProduct = () => {
    const navigate = useNavigate()
    const match = useMatch('/product/clone/:id')
    const { language_data, authState, loading, setLoading, toastMessage, authDispatch } = useContext(GlobalContext);
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_clone}`, url: ``, active: 'not-allowed' }
    ]

    const [state, setState] = useState({
        name: '',
        description: '',
        brand_id: '',
        category_id: '',
        subcategory_id: '',
        sub_subcategory_id: '',
        tax_percentage: '',
        quantity: '',
        price: '',
        discount_percantage: '',
        language: ''
    } as dataHolder);
    const [specification, setSpecification] = useState({
        key: "",
        value: ""
    } as MultipleSelect)
    const [brandDetails, setBrandDetails] = useState({
        brand: [],
        category: [],
        subcategory: [],
        subSubcategory: [],
    } as brandListing)
    const [taxErr, setTaxErr] = useState<string>("")
    const [nameErr, setNameErr] = useState<string>("")
    const [brandErr, setBrandErr] = useState<string>("")
    const [priceErr, setPriceErr] = useState<string>("")
    const [services, setServices] = useState<string>("")
    const [highlights, setHighlights] = useState<string>("")
    const [servicesErr, setServicesErr] = useState<string>("")
    const [categoryErr, setCategoryErr] = useState<string>("")
    const [quantityErr, setQuantityErr] = useState<string>("")
    const [highlightsErr, setHighlightsErr] = useState<string>("")
    const [subCategoryErr, setSubcategoryErr] = useState<string>("")
    const [descriptionErr, setDescriptionErr] = useState<string>("")
    const [cloneLoading, setCloneLoading] = useState<boolean>(false)
    const [selectedFileErr, setSelectedFileErr] = useState<string>('')
    const [imagesClone, setImagesClone] = useState([] as Array<string>)
    const [specificationErr, setSpecificationErr] = useState<string>('')
    const [servicesArray, setServicesArray] = useState([] as Array<string>)
    const [highlightsArray, setHighlightsArray] = useState([] as Array<string>)
    const [selectedFile, setSelectedFile] = useState<Array<Blob | MediaSource>>([])
    const [specificationArray, setSpecificationArray] = useState([] as Array<MultipleSelect>)
    const productCloneDetails = async () => {
        setCloneLoading(true)
        try {
            let productRes = (await henceforthApi.Product.getProductDetails(match?.params.id)).data
            setState({
                name: productRes?.name,
                description: productRes?.description,
                brand_id: productRes?.brand_id?._id,
                category_id: productRes?.category_id?._id,
                subcategory_id: productRes?.subcategory_id?._id,
                sub_subcategory_id: productRes?.sub_subcategory_id?._id,
                tax_percentage: productRes?.tax_percentage,
                quantity: productRes?.quantity,
                price: productRes?.price,
                discount_percantage: productRes?.discount_percantage,
                language: 'ENGLISH'
            })
            setImagesClone(productRes?.images)
            setHighlightsArray(productRes?.product_highlights?.map((res: any) => res?.content))
            setServicesArray(productRes?.product_services.map((res: any) => res?.content))
            setSpecificationArray(productRes?.productdetails.map((res: any) => { return { key: res?.key, value: res?.value } }))

        } catch (err) {
            handleError(err, '', authDispatch);
        } finally {
            setCloneLoading(false)
        }
    }
    useEffect(() => {
        productCloneDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const uploadImages = async () => {
        const images = [...imagesClone] as Array<string>
        await Promise.all(selectedFile.map(async (res) => {
            try {
                const apiRes = await henceforthApi.Common.do_spaces_file_upload(`file`, res)
                const data = apiRes.data
                images.push(data.file_name)
            } catch (e) {
                console.log(e);
                handleError(e, 'active', authDispatch);
            }
        }
        ))
        return images
    }
    const brandListing = async () => {
        try {
            let apiBrandRes = (await henceforthApi.categorylisting.Brandlisting()).data.data;
            let apiCategoryRes = (await henceforthApi.categorylisting.categorylisting()).data.data;
            setBrandDetails((brandDetails: any) => {
                return {
                    ...brandDetails,
                    brand: apiBrandRes,
                    category: apiCategoryRes,
                }
            });

        } catch (err) {
            handleError(err, '', authDispatch);
        }
    };
    const subListing = async () => {
        try {

            let apiSubCategoryRes = (await henceforthApi.categorylisting.subCategorylisting(state.category_id)).data.data;
            setBrandDetails((brandDetails: any) => {
                return {
                    ...brandDetails,
                    subcategory: apiSubCategoryRes,
                }
            });
        } catch (err) {
            handleError(err, '', authDispatch);
        }
    }
    const sub_subListing = async () => {
        try {

            let apiSubSubCategoryRes = (await henceforthApi.categorylisting.subSubCategorylisting(state.subcategory_id)).data.data;
            setBrandDetails((brandDetails: any) => {
                return {
                    ...brandDetails,
                    subSubcategory: apiSubSubCategoryRes,
                }
            });
        } catch (err) {
            handleError(err, '', authDispatch);

        }
    }
    useEffect(() => {
        if ((state.category_id ?? '') !== '') {
            subListing()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.category_id])
    useEffect(() => {
        if ((state.subcategory_id ?? '') !== "") {
            sub_subListing()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.subcategory_id])
    useEffect(() => {
        brandListing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleFile = (e: any) => {
        let files = e.target.files as Array<Blob | MediaSource>
        setSelectedFile([...selectedFile, ...files])
        e.target.value = ""
    };
    const clearInputErr = (name: string) => {
        if (name === 'name') setNameErr('')
        if (name === 'description') setDescriptionErr('')
        if (name === 'brand_id') setBrandErr('')
        if (name === 'category_id') setCategoryErr('')
        if (name === 'subcategory_id') setCategoryErr('')
        if (name === 'subcategory_id') setCategoryErr('')
        if (name === 'tax_percentage') setTaxErr('')
        if (name === 'quantity') setQuantityErr('')
        if (name === 'price') setPriceErr('')
    }
    const handleChange = async (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        clearInputErr(name)
        let res = formValidate(name, value)
        if (res === false) return
        console.log(isNaN(value))

        if (name === 'category_id' && !value) setState((state) => { return { ...state, subcategory_id: '', sub_subcategory_id: '' } });
        if (name === 'subcategory_id' && !value) setState((state) => { return { ...state, sub_subcategory_id: '', } });
        setState({
            ...state,
            [name]: value,
        });
    };
    const handleSubmit = async () => {
        if (!selectedFile.length && !state.name && !state.description && !state.tax_percentage && !state.brand_id && !state.category_id && !state.subcategory_id && !specificationArray.length && !state.quantity && !state.price && !servicesArray.length && !highlightsArray.length) {
            setSelectedFileErr(`${language_data?.[authState.lang]?.common_image}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setSpecificationErr(`${language_data?.[authState.lang]?.common_specifications}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setHighlightsErr(`${language_data?.[authState.lang]?.common_highlight}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setNameErr(`${language_data?.[authState.lang]?.common_name}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setDescriptionErr(`${language_data?.[authState.lang]?.common_description}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setBrandErr(`${language_data?.[authState.lang]?.common_brand}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setTaxErr(`${language_data?.[authState.lang]?.common_tax}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setCategoryErr(`${language_data?.[authState.lang]?.common_category}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setSubcategoryErr(`${language_data?.[authState.lang]?.common_sub_category}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setQuantityErr(`${language_data?.[authState.lang]?.common_quantity}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setPriceErr(`${language_data?.[authState.lang]?.common_price}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setServicesErr(`${language_data?.[authState.lang]?.common_services}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            return
        }
        if (selectedFile.length === 0 && imagesClone.length === 0) { setSelectedFileErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_images}`); documentId('images'); return }
        if (!state.name.trim()) { documentId('productName'); setNameErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_provide}${language_data?.[authState.lang]?.common_name}`); return }
        if (!state.description.trim()) { documentId('description'); setDescriptionErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_provide}${language_data?.[authState.lang]?.common_description}`); return }
        if (!state.price) { documentId('price'); setPriceErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_price}`); return }
        if (!Number(state.price)) { documentId('price'); setPriceErr(`${language_data?.[authState.lang]?.common_only}${language_data?.[authState.lang]?.common_use}${language_data?.[authState.lang]?.common_number}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_price}`); return }
        if (!state.tax_percentage) { documentId('tax_percentage'); setTaxErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_tax}`); return }
        if (!Number(state.tax_percentage)) { documentId('tax_percentage'); setTaxErr(`${language_data?.[authState.lang]?.common_only}${language_data?.[authState.lang]?.common_use}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_number}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_tax}`); return }
        if (!state.quantity) { documentId('quantity'); setQuantityErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_quantity}`); return }
        if (!Number(state.quantity)) { documentId('quantity'); setQuantityErr(`${language_data?.[authState.lang]?.common_only}${language_data?.[authState.lang]?.common_use}${language_data?.[authState.lang]?.common_number}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_quantity}`); return }
        if (!state.brand_id.trim()) { documentId('brand_id'); setBrandErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_brand}`); return }
        if (!state.category_id.trim()) { documentId('category_id'); setCategoryErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_category}`); return }
        if (!state.subcategory_id.trim()) { documentId('subcategory_id'); setSubcategoryErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_sub_category}`); return }
        if (highlightsArray.length === 0) { documentId('highlights'); setHighlightsErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_atleast}${language_data?.[authState.lang]?.common_one}${language_data?.[authState.lang]?.common_highlight}`); return }
        if (servicesArray.length === 0) { documentId('services'); setServicesErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_atleast}${language_data?.[authState.lang]?.common_one}${language_data?.[authState.lang]?.common_services}`); return }
        if (specificationArray.length === 0) { documentId('specification'); setSpecificationErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_atleast}${language_data?.[authState.lang]?.common_one}${language_data?.[authState.lang]?.common_specifications}`); return }
        setLoading(true)
        const images = await uploadImages()
        const items = {
            name: state.name,
            description: state.description,
            brand_id: state.brand_id,
            clone_product_id: match?.params.id,
            category_id: state.category_id,
            subcategory_id: state.subcategory_id,
            images,
            product_details: specificationArray,
            quantity: Number(state.quantity),
            price: Number(state.price),
            tax_percentage: Number(state.tax_percentage),
            discount_percantage: Number(state.discount_percantage),
            services: servicesArray,
            highlights: highlightsArray,
            language: "ENGLISH"
        } as any
        if (state.sub_subcategory_id) items['sub_subcategory_id'] = state.sub_subcategory_id
        console.log(items);
        try {
            const apiRes = await henceforthApi.Product.add(items)
            let variantsItems = {
                product_id_1: match?.params.id,
                product_id_2: apiRes.data._id,
                language: "ENGLISH"
            }
            await henceforthApi.Product.addProductDetailsDynamically(commonArray.productOtherDetails[2], variantsItems)
            toastMessage(apiRes.message)
            navigate(`/product/${apiRes.data._id}`)
        } catch (error) {
            handleError(error, 'active', authDispatch);

        } finally {
            setLoading(false)
        }
    };
    const onChangeSpecifications = (index?: number | null) => {
        if (index !== null) {
            specificationArray.splice(index as number, 1)
            setSpecificationArray([...specificationArray])
        } else {
            setSpecificationArray([...specificationArray, specification])
            setSpecification({ key: "", value: "" });
        }
    }
    const onChangeHighlights = (index?: number | null) => {
        if (index !== null) {
            highlightsArray.splice(index as number, 1)
            setHighlightsArray([...highlightsArray])
        } else {
            setHighlightsArray([...highlightsArray, highlights])
            setHighlights('');
        }
    }

    const onChangeServices = (index?: number | null) => {
        if (index !== null) {
            servicesArray.splice(index as number, 1)
            setServicesArray([...servicesArray])
        } else {
            setServicesArray([...servicesArray, services])
            setServices('');
        }
    }
    const imageRemove = (del: string, id: number) => {
        if (del) {
            imagesClone.splice(id, 1)
            setImagesClone([...imagesClone])
        } else {
            selectedFile.splice(id, 1)
            setSelectedFile([
                ...selectedFile,
            ])
        }
    }
    const onChangeInputHandler = (name: string, value: string) => {
        if (name === "highlights") {
            setHighlights(value)
            setHighlightsErr('')
        } else if (name === 'services') {
            setServices(value)
            setServicesErr('')
        }
    }
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            {cloneLoading ? <div className='data-list-table table-responsive mt-5 text-center'>
                <Spinner color={"text-success"} /> </div> :
                <div className='page-spacing'>
                    <section className='product-detail'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    {/* Title  */}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <h5>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_product}</h5>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content">
                                            {/* <form onSubmit={handleSubmit}> */}
                                            <div className="upload-fields-box mb-3">
                                                <label htmlFor="" className='fw-bolder mb-2'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_images}</label>
                                                {/* Upload image */}
                                                <div className="product-edit-image d-flex gap-2 gap-md-4 gap-lg-5 flex-column flex-sm-row">
                                                    <div className="product-edit-upload">
                                                        <div className='upload-fields-box mb-3'>
                                                            <div className='profile-edit-image mb-2 mx-0 product-edit-upload-image'>
                                                                <div className='profile-edit-upload is-invalid'>
                                                                    <input
                                                                        id="images"
                                                                        onChange={(e) => { setSelectedFileErr(''); handleFile(e) }}
                                                                        type="file"
                                                                        className={`form-control ${selectedFileErr ? 'is-invalid' : ''}`}
                                                                        accept='image/png,image/jpeg'
                                                                        multiple
                                                                    />
                                                                </div>
                                                                <img src={imageUpload} alt="img" className={`${selectedFileErr ? 'border border-danger' : ''}`} />
                                                            </div>
                                                            <Errormessage phone={selectedFileErr} error={selectedFileErr} />
                                                        </div>
                                                    </div>
                                                    <div className='product-images-preview d-inline-flex gap-sm-4 gap-md-4 gap-lg-5 gap-3 flex-wrap flex-column flex-sm-row'>
                                                        {Array.isArray(imagesClone) && imagesClone.map((res, index: number) => {
                                                            return (
                                                                <div key={index} className='preview-image-box position-relative'>
                                                                    <img
                                                                        className="Product-Images border border-light"
                                                                        src={`${henceforthApi.API_FILE_ROOT_MEDIUM}${res}`} alt={res} />
                                                                    {imagesClone[0] !== "" && <span key={Math.random()} className='preview-image-close ' onClick={() => imageRemove("del", index)}><i className='fa fa-close text-dark' style={{ cursor: 'pointer' }}></i> </span>}
                                                                </div>
                                                            )
                                                        })}
                                                        {Array.isArray(selectedFile) && selectedFile.length ? selectedFile.map((res, index: number) => {

                                                            return (
                                                                <div key={index} className='preview-image-box position-relative'><img
                                                                    className="Product-Images border border-light"
                                                                    src={URL.createObjectURL(res)} alt="img" />
                                                                    {<span key={Math.random()} className='preview-image-close ' onClick={() => imageRemove('', index)}><i className='fa fa-close text-dark' style={{ cursor: 'pointer' }}></i> </span>}
                                                                </div>
                                                            )
                                                        }) : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <p><small><strong>{language_data?.[authState.lang]?.common_note}:-</strong> {language_data?.[authState.lang]?.common_please_upload_only_jpg_and_png_format_only}.</small></p>
                                            <div className='divider my-3'></div>
                                            {/* Product Name  */}
                                            <Inputs.Input type='text' id="productName" error={nameErr} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_name}`} name='name' placeholder={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_name}`} value={state.name} handleChange={handleChange} />
                                            {/* Product Description  */}
                                            <Textarea error={descriptionErr} id="description" col={30} row={5} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_description}`} name='description' placeholder={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_description}`} value={state.description} handleChange={handleChange} />
                                            {/* Product Price  */}
                                            <Inputs.Input type='text' id="price" error={priceErr} label={`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_price}`} name='price' placeholder={`${language_data?.[authState.lang]?.common_price}`} value={state.price} handleChange={handleChange} />
                                            {/* Product tax  */}
                                            <Inputs.Input type='text' error={taxErr} label={`${language_data?.[authState.lang]?.common_tax} ${language_data?.[authState.lang]?.common_percentage} %`} name='tax_percentage' placeholder={`${language_data?.[authState.lang]?.common_tax} ${language_data?.[authState.lang]?.common_percentage}`} value={state.tax_percentage} handleChange={handleChange} />
                                            {/* Quantity */}
                                            <Inputs.Input type='number' id="quantity" error={quantityErr} label={`${language_data?.[authState.lang]?.common_quantity} `} name='quantity' placeholder={`${language_data?.[authState.lang]?.common_quantity} `} value={state.quantity} handleChange={handleChange} />
                                            {/* Brand  */}
                                            <Select Array={brandDetails.brand} id="brand_id" error={brandErr} label={`${language_data?.[authState.lang]?.common_brand} `} firstSelect='Brand' name='brand_id' value={state.brand_id} handleChange={handleChange} />
                                            {/* Category-1  */}
                                            <Select Array={brandDetails.category} id="category_id" error={categoryErr} label={`${language_data?.[authState.lang]?.common_category_level}  ${language_data?.[authState.lang]?._1} `} firstSelect='Category' name='category_id' value={state.category_id} handleChange={handleChange} />
                                            {/* Category-2  */}
                                            <Select Array={brandDetails.subcategory} disabled={!state.category_id} error={subCategoryErr} label={`${language_data?.[authState.lang]?.common_category_level}  ${language_data?.[authState.lang]?._2} `} firstSelect='SubCategory' name='subcategory_id' value={state.subcategory_id} handleChange={handleChange} />
                                            {/* Category-3  */}
                                            <Select Array={brandDetails.subSubcategory} disabled={!state.subcategory_id} label={`${language_data?.[authState.lang]?.common_category_level}  ${language_data?.[authState.lang]?._3} `} firstSelect='Sub_SubCategory' name='sub_subcategory_id' value={state.sub_subcategory_id} handleChange={handleChange} />
                                            {/* Discount  */}
                                            <Inputs.Input type='number' label={`${language_data?.[authState.lang]?.common_discount} `} name='discount_percantage' placeholder={`${language_data?.[authState.lang]?.common_enter_your}  ${language_data?.[authState.lang]?.common_discount} `} value={state.discount_percantage} handleChange={handleChange} />
                                            {/* <Select Array={commonArray.discount} label='Discount' firstSelect='Discount' name='discount_percantage' value={state.discount_percantage} handleChange={handleChange} /> */}
                                            <div className="divider my-3"></div>
                                            {/* Highlights  */}
                                            <Inputs.InputWButton id="highlights" Array={highlightsArray} error={highlightsErr} placeholder={`${language_data?.[authState.lang]?.common_enter}  ${language_data?.[authState.lang]?.common_highlights} `} label={`${language_data?.[authState.lang]?.common_highlights}  `} name='highlights' value={highlights} onChangeInputHandler={onChangeInputHandler} onChangeHighlights={onChangeHighlights} />
                                            <div className="divider my-3"></div>
                                            {/* Services  */}
                                            <Inputs.InputWButton id="services" Array={servicesArray} error={servicesErr} placeholder={`${language_data?.[authState.lang]?.common_enter}  ${language_data?.[authState.lang]?.common_services} `} label={`${language_data?.[authState.lang]?.common_services} `} name='services' value={services} onChangeInputHandler={onChangeInputHandler} onChangeHighlights={onChangeServices} />
                                            <div className="divider my-3"></div>

                                            {/* Specifications */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className='fw-bolder mb-3'>{language_data?.[authState.lang]?.common_specifications}</h4>
                                                </div>
                                            </div>
                                            <div className="form-fields-box mb-4">
                                                <form className="form-fields-box d-flex gap-2 mb-3" onSubmit={(e) => { e.preventDefault(); onChangeSpecifications(null); setSpecificationErr('') }}>
                                                    <input type="text" id="specification" className={`form-control rounded-0 ${specificationErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?.common_key} `} name="key"
                                                        value={specification.key}
                                                        onChange={(e) => { setSpecificationErr(''); setSpecification({ ...specification, key: e.target.value }) }} />
                                                    <input type="text" className={`form-control rounded-0 ${specificationErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?.common_value} `} name="value"
                                                        value={specification.value}
                                                        onChange={(e) => { setSpecificationErr(''); setSpecification({ ...specification, value: e.target.value }) }} />
                                                    <button type="submit" className="btn btn-theme" disabled={!specification.key.trim() || !specification.value.trim()}>
                                                        <i className='fa fa-plus text-white'></i>
                                                    </button>
                                                </form>
                                                <Errormessage phone={specificationErr} error={specificationErr} />
                                                {/* Added Specifiactions  */}
                                                <ul className='ps-0 m-0 d-flex gap-4 flex-wrap'>
                                                    {specificationArray.map((res: any, index: any) => <li key={index}>{res.key}: {res.value}
                                                        <i className='fa fa-trash text-danger fs-6 ms-2' role="button" onClick={() => onChangeSpecifications(index)}></i>
                                                    </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="divider my-3"></div>
                                            {/* Buttons  */}
                                            <SaveCancelButton loading={loading} color="text-white" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
        </Fragment>
    )
}
export default CloneProduct;