import '../../assets/styles/auth.scss'
import logo from '../../assets/images/logo/logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import henceforthApi from '../../utils/henceforthApi';
import loginSuccess from '../../context/actions/auth/loginSuccess';
import { Fragment, useContext, useState } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import { strongPasswordValidation, validateEmail } from '../../utils/validations';
import Spinner from '../../components/common/spinner';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Errormessage from '../../components/common/errormessage';
import Svg from "../../components/common/error-svg"
import { requestNotification } from '../../utils/firebase';
import VerifyOtp from '../../components/modals/VerifyEmailOtp';
import henceofrthEnums from '../../utils/henceofrthEnums';



const Signup = () => {
  const navigate = useNavigate()
  const [firstEye, setFirstEye] = useState(true);
  const { authDispatch, toastMessage, loading, setLoading, language_data, authState } = useContext(GlobalContext);
  const [nameErr, setNameErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [phoneErr, setPhoneErr] = useState('')
  const [passwordEr, setPasswordEr] = useState('')
  const [checkboxEr, setCheckboxEr] = useState('')
  const [checkbox, setcheckbox] = useState(false)
  const [openEmailVerification, setOpenEmailVerification] = useState<boolean>(false)
  const [state, setState] = useState({
    name: "",
    email: "",
    country_code: "",
    phone_number: "",
    password: "",
    loading: false,
  });
  const [store, setStore] = useState({} as any)
  const submitForm = async (e: any) => {
    e.preventDefault();
    // console.log("onSubmitForm called");
    if (!state.name && !state.email && !state.phone_number && !state.password && !checkbox) {
      setEmailErr("email is required")
      setNameErr("name is required")
      setPasswordEr("password is required")
      setPhoneErr("phone is required")
      setCheckboxEr("please agree to terms and condition")
      return
    }
    if (!state.name.trim()) return setNameErr("please provide valid name");
    if (!state.email) { return setEmailErr("please fill valid email") }
    if (!validateEmail(state.email)) return setEmailErr("please fill valid email");
    if (!state.phone_number.trim()) { return setPhoneErr("phone is required") }
    if (state.phone_number.length < 10 || state.phone_number.length > 12) return setPhoneErr("please provide valid number");
    if (!state.password.trim()) { { return setPasswordEr("password is required") } }
    if (!strongPasswordValidation(state.password)) { return setPasswordEr("password must be atleast 8 character lond and contain one uppercase and one lowecase characters with special characters and numbers"); }
    if (!checkbox) { return setCheckboxEr("please agree to terms and condition") }
    setState({
      ...state,
      loading: true,
    });
    let fcmid = await requestNotification()
    const { name, email, country_code, phone_number, password } = state;
    let items = {
      name: name,
      email: email,
      country_code: country_code,
      phone_number: phone_number,
      password: password,
      language: "ENGLISH",
    } as any;
    if (fcmid) items['fcm_token'] = fcmid

    // console.log("items", items);
    try {
      let res = await henceforthApi.Auth.signup(items)
      setStore(res.data)
      // loginSuccess(res.data)(authDispatch);
      toastMessage("SignUp Successfully");
      setOpenEmailVerification(true)
    }
    catch (err) {
      handleError(err, 'active', authDispatch);
    }
    finally {
      setState({
        ...state,
        loading: false,
      });

    }
  };
  const changeHanlder = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const phoneCheck = (e: any, v: any) => {
    setPhoneErr('')
    setState({
      ...state,
      phone_number: e.phone.slice(Number(v.dialCode.length), Number(e.phone.length)),
      country_code: v.dialCode,
    });
  }
  henceforthApi.setToken(store.access_token)
  const initialiseProfile = async (data: any) => {
    try {
      let apiRes = await henceforthApi.Auth.emailVerifyOtp({ otp: data, language: 'ENGLISH' })
      console.log('apiRes', apiRes);
      loginSuccess({ ...apiRes.data, lang: henceofrthEnums.Language.ENGLISH })(authDispatch);
      henceforthApi.languageChange(henceofrthEnums.Language.ENGLISH)
      setOpenEmailVerification(false)
      navigate(`/`)

    } catch (err) {
      handleError(err, 'active', authDispatch)
    }

  }
  const resendEmailOtp = async () => {
    if (loading) return
    setLoading(true)
    try {
      let items = {
        email: state.email,
        language: "ENGLISH"
      }
      let apiRes = await henceforthApi.Auth.emailVerifySendOtp(items)
      return apiRes.data
    } catch (error: any) {
      handleError(error, 'active', authDispatch)
    } finally {
      setLoading(false)
    }
  }
  const onCloseModal = (close: boolean) => {
    setOpenEmailVerification(close)
    loginSuccess({...store,lang:henceofrthEnums.Language.ENGLISH})(authDispatch)
    henceforthApi.setToken(store.access_token)
    henceforthApi.languageChange(henceofrthEnums.Language.ENGLISH)
    navigate('/')
    // let error = { response: { body: { error_description: "Please Verify your Email" } } }
    // handleError(error, 'active', authDispatch)
  }
  return (
    <Fragment>
      <section className='auth-pages'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-10">
              <div className="auth-page-card mb-4">
                {/* logo */}
                <div className='auth-logo-box text-center'>
                  <img src={logo} height={100} width={100} alt="img" />
                </div>
                {/* title  */}
                <div className='auth-page-card-title text-center my-4'>
                  <h3 className='mb-2'>Register to henceforth </h3>
                  <p>Create account to see it in action.</p>
                </div>
                {/* form  */}
                <form onSubmit={submitForm} >
                  {/* Name  */}
                  <div className='form-fields-box mb-3'>
                    <input type="text" className={`form-control rounded-0 ${nameErr ? 'is-invalid' : ''}`} placeholder="Name" name='name' onChange={(e: any) => { setNameErr(''); changeHanlder(e); }} />
                    <Errormessage class={nameErr} error={nameErr} />
                  </div>
                  {/* Email  */}
                  <div className='form-fields-box mb-3'>
                    <input type="text" className={`form-control rounded-0 mb-2 ${emailErr ? 'is-invalid' : ''}`} placeholder="Email" name='email' onChange={(e: any) => { setEmailErr(''); changeHanlder(e); }} />
                    <Errormessage class={emailErr} error={emailErr} />
                  </div>

                  {/* Phone Number  */}
                  <div className={`form-fields-box mb-2 is-invalid rounded-0 ${phoneErr ? 'border border-danger position-relative' : ""}`}>
                    <PhoneInput country={'us'} onChange={(phone: any, value: any) => { phoneCheck({ phone }, value) }} />
                    {/* <input type="tel" className="form-control rounded-0" placeholder="Phone Number" name='phone_number' maxLength={10} onChange={(e: any) => { changeHanlder(e); phoneCheck(e.target.value) }} /> */}
                    {phoneErr ? <span className='svg-question'>
                      <Svg /></span> : ''}
                  </div>
                  <Errormessage phone={phoneErr} error={phoneErr} />
                  {/* Password  */}
                  <div className={`form-fields-box mb-3 ${phoneErr ? '' : 'mt-3'}`}>
                    <div className='form-fields-box mb-4'>
                      <div className="input-group is-invalid">
                        <input type={firstEye ? "password" : "text"}
                          name='password' onChange={(e: any) => { setPasswordEr(''); changeHanlder(e); }}
                          value={state.password}
                          className={`form-control rounded-0 ${passwordEr ? 'is-invalid' : ''}`} placeholder="Password" />
                        <div className="input-group-append">
                          <span className={`input-group-text group-btn-span ${passwordEr ? 'border border-danger' : ''}`} onClick={() => setFirstEye(!firstEye)}> <i
                            className={`fa ${firstEye ? `fa-eye ${passwordEr ? 'text-danger' : 'text-secondary'}` : `fa-eye-slash ${passwordEr ? 'text-danger' : 'text-secondary'}`}`}

                            aria-hidden="true"
                          ></i></span>
                        </div>
                      </div>
                      <Errormessage class={passwordEr} error={passwordEr} />
                    </div>
                  </div>
                  {/* Upload Image  */}
                  {/* <div className='form-fields-box mb-3'>
                    <input type="file" className="form-control rounded-0" placeholder="image" />
                    <p className='mt-1'><small><strong>Note:-</strong> Please upload only .jpg and .png format only.</small></p>
                  </div> */}
                  {/* Terms and Conditions  */}
                  <div className='form-checkbox-box auth-form-links mb-4'>
                    <div className="form-check is-invalid">
                      <input className={`form-check-input shadow-none rounded-0${checkboxEr ? "is-invalid border border-danger" : ""}`} type="checkbox" value="" id="flexCheckDefault" onChange={(e) => { setcheckbox(e.target.checked); setCheckboxEr('') }} />
                      <label className="form-check-label" htmlFor="flexCheckDefault">Agree the <Link to="/terms">Term's</Link> and <Link to="/terms">Condition</Link>.</label>
                    </div>
                    <Errormessage class={checkboxEr} error={checkboxEr} />
                  </div>
                  {/* Register button  */}
                  <div className='form-fields-box'>
                    <button type='submit' className='btn btn-theme w-100' disabled={state.loading}>
                      {state.loading ? <Spinner color={"text-white"} /> : `Register`}
                    </button>
                  </div>
                </form>
              </div>
              {/* Have Account  */}
              <div className='have-account-box'>
                <div className='form-fields-box text-center mb-3'>
                  <p>Already have an account?</p>
                </div>
                {/* Login button  */}
                <div className='form-fields-box'>
                  <Link to="/signin" type='submit' className='btn btn-white w-100'>SignIn</Link>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >
      {openEmailVerification &&
        <VerifyOtp verifyCallback={initialiseProfile} resendOtpCallback={resendEmailOtp} onCloseModal={onCloseModal} />
      }
    </Fragment>
  );
};

export default Signup;
