import { Fragment, useContext, useState } from "react";
import { useMatch } from "react-router-dom";
import BreadCrumb from "../../components/common/BreadCrumb";
import { SaveCancelButton } from "../../components/common/Inputs";
import Select from "../../components/common/Select";
import Textarea from "../../components/common/Textarea";
import { GlobalContext, handleError } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
type cancel = {
    cancellation_reason: string,
    description: string
}
const OrderCancelSeller = () => {
    const {language_data, authState, loading, setLoading, authDispatch } = useContext(GlobalContext)
    const match = useMatch('/order/cancel/:id/:order_id')
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name:` ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_list}` , url: `/orders/1`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_deatil}`, url: `/order/${match?.params.id}`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_cancel}`, url: ``, active: 'not-allowed' }
    ]
    const reasons = [
        { _id: 'OUT_OF_STOCK', name: 'Out Of Stock' },
        { _id: 'FOR_OTHER_REASON', name: 'For Other Reason' },
    ]
    const [cancel, setCancel] = useState({
        cancellation_reason: '',
        description: ''
    } as cancel)
    
    const [cancelErr, setCancelErr] = useState<string>('')
    const [descriptionErr, setDescriptionErr] = useState<string>('')
    const handleChange = ({ target }: any) => {
        let name = target.name
        let value = target.value
        if (name === 'cancellation_reason') setCancelErr('')
        if (name === 'description') setDescriptionErr('')
        setCancel((cancel: any) => {
            return {
                ...cancel,
                [name]: value
            }
        })
    }
    const cancelRequest = async (e: any) => {
        e.preventDefault();
        if (!cancel.cancellation_reason && !cancel.description.trim()) {
            setCancelErr(`${language_data?.[authState.lang]?.common_reason}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setDescriptionErr(`${language_data?.[authState.lang]?.common_comments}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        }
        if (!cancel.cancellation_reason) return setCancelErr(`${language_data?.[authState.lang]?.common_reason}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (!cancel.description) return setDescriptionErr(`${language_data?.[authState.lang]?.common_comments}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (!cancel.description.trim()) return setDescriptionErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_provide}${language_data?.[authState.lang]?.common_comments}`)
        setLoading(true)
        try {
            await henceforthApi.Order.deleteOrder({
                _id: match?.params.id,
                order_id: match?.params.order_id,
                cancellation_reason: cancel.cancellation_reason,
                description: cancel.description,
                language: 'ENGLISH'
            })
            window.history.back()
            setCancel({
                ...cancel,
                cancellation_reason: '',
                description: ''
            })
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
            console.log(err.response.body.error_description);
        } finally {
            setLoading(false)
        }
    }
    return <Fragment>
        <BreadCrumb pathNameDeclare={breadCrumbPath} />
        <div className='page-spacing'>
            <section className='product-detail'>
                <div className="row">
                    {/* form  */}
                    <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-3">
                        <div className="common-card">
                            <div className="common-card-title">
                                <div className="profile-heading">
                                    <h5 className="m-0">{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_cancellation}</h5>
                                </div>
                            </div>
                            <div className="common-card-content">
                                <form onSubmit={cancelRequest} >
                                    {/* Select Reason  */}
                                    <Select Array={reasons} error={cancelErr} label={`${language_data?.[authState.lang]?.common_reason} ${language_data?.[authState.lang]?.common_for} ${language_data?.[authState.lang]?.common_cancellation}`} firstSelect='Reason' name='cancellation_reason' value={cancel.cancellation_reason} handleChange={handleChange} />
                                    {/* comment  */}
                                    <Textarea error={descriptionErr} col={30} row={5} label={`${language_data?.[authState.lang]?.common_comment}`} name='description' placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_comment}`} value={cancel.description} handleChange={handleChange} />
                                    {/* button  */}
                                    <SaveCancelButton loading={loading} color="text-white" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
}
export default OrderCancelSeller;