import moment from "moment"
import { Fragment, useContext, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { GlobalContext } from "../../context/Provider"
import henceofrthEnums from "../../utils/henceofrthEnums"
import { capitalise, NumberValidation } from "../../utils/validations"
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from "react-toastify"
const filter = [
    { min_price: '100', max_price: '500' },
    { min_price: '501', max_price: '1000' },
    { min_price: '1001', max_price: '2000' },
]
const AllFilter = (props: any) => {
    const location = useLocation()
    const newParam = new URLSearchParams(location.search)
    const { handleSearch, onFilterPriceHandler,language_data,authState } = useContext(GlobalContext)
    const [min_price, setMin_price] = useState<any>('')
    const [max_price, setMax_price] = useState<any>('')
    const [showCalendar, setShowCalendar] = useState(false)
    const calendarDropRef = useRef<any>(null)

    const dateSelect = (e: any) => {
        handleSearch('start_date', e[0] ? String(moment(e[0]).valueOf()) : '')
        handleSearch('end_date', e[1] ? String(moment(e[1]).valueOf()) : '')
        setShowCalendar(false)
    }
    const handleClickOutside = (e: any) => {
        if (calendarDropRef.current && !calendarDropRef?.current.contains(e.target)) {
            setShowCalendar(false)
        }
    }
    const onChangeprice = async (e: any) => {
        e.preventDefault();
        if (Number(min_price) > Number(max_price)) return toast.warn(`${language_data?.[authState.lang]?.common_max} ${language_data?.[authState.lang]?.common_price} ${language_data?.[authState.lang]?.common_should_be_greater_than} ${language_data?.[authState.lang]?.common_min} ${language_data?.[authState.lang]?.common_price}`);
        onFilterPriceHandler(min_price, max_price)
        setMax_price('')
        setMin_price('')

    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const filters = [
        {
            active: props.stock, label:`${language_data?.[authState.lang]?.common_stock} ${language_data?.[authState.lang]?.common_status}` , labelClass: newParam.has("stock"), Array: [
                { lableName: `${language_data?.[authState.lang]?.common_out_of_stock} `, queryValue: newParam.get("stock"), value: 'OUT_OF_STOCK', query: 'stock' },
                { lableName: `${language_data?.[authState.lang]?.common_alert_of_stock} `, queryValue: newParam.get("stock"), value: 'ALERT_OF_STOCK', query: 'stock' }
            ]
        },
        {
            active: props.orderStatus, label: `${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_status}`, labelClass: newParam.has("order_status"), Array: [
                { lableName: `${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("order_status"), value: henceofrthEnums.OrderStatus.CONFIRMED, query: 'order_status' },
                { lableName: `${language_data?.[authState.lang]?.common_canceled} ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("order_status"), value: henceofrthEnums.OrderStatus.CANCELLED, query: 'order_status' },
                { lableName: `${language_data?.[authState.lang]?.common_delivered} ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("order_status"), value: henceofrthEnums.OrderStatus.DELIVERED, query: 'order_status' },
                { lableName: `${language_data?.[authState.lang]?.common_shipped} ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("order_status"), value: henceofrthEnums.OrderStatus.SHIPPED, query: 'order_status' }
            ]
        },
        {
            active: props.refund, label: `${language_data?.[authState.lang]?.common_refund} ${language_data?.[authState.lang]?.common_status}`, labelClass: newParam.has("payment_status"), Array: [
                { lableName: `${language_data?.[authState.lang]?.common_refunded} ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("payment_status"), value: henceofrthEnums.OrderStatus.REFUNDED, query: 'payment_status' },
                { lableName: `${language_data?.[authState.lang]?.common_refund} ${language_data?.[authState.lang]?.common_in_progress}  ${language_data?.[authState.lang]?.common_status}`, queryValue: newParam.get("payment_status"), value: henceofrthEnums.OrderStatus.REFUND_IN_PROGESS, query: 'payment_status' }
            ]
        },
    ]
    return <div className='common-card mb-4 border-0 card-spacing'>
        <div className="row justify-content-between gy-3">
            {/* serach and filter  */}
            <div className="col-lg-10">
                <div className="row gy-3">
                    <div className="col-md-4 col-sm-5">
                        <div className='form-fields-box'>
                            <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.common_search}</label>
                            <div className='position-relative'>
                                <input type="search" className="form-control rounded-0 ps-4 " name='search' value={newParam.has("search") ? newParam.get("search") as string : ""} placeholder={language_data?.[authState.lang]?.common_search}
                                    onChange={(e) => {
                                        handleSearch(e.target.name, e.target.value)
                                    }} />
                                <span className='search-icon'><i className='fa fa-search'></i></span>
                            </div>
                        </div>
                    </div>
                    {props.product_id ?
                        <div className="col-md-4 col-sm-4">
                            <div className='form-fields-box'>
                                <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.common_search} {language_data?.[authState.lang]?.common_product}{language_data?.[authState.lang]?.common_id}</label>
                                <div className='position-relative'>
                                    <input type="search" className="form-control rounded-0 ps-4 " name='product_id' value={newParam.has("product_id") ? newParam.get("product_id") as string : ""} placeholder={`${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_id}`}
                                        onChange={(e) => {
                                            handleSearch(e.target.name, e.target.value)
                                        }} />
                                    <span className='search-icon'><i className='fa fa-search'></i></span>
                                </div>
                            </div>
                        </div> : ''}
                    {/* Price Range  */}
                    <div className="col-lg-3 col-sm-4">
                        <div className='form-select-box'>
                            <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.common_price} {language_data?.[authState.lang]?.common_filter}</label>
                            <div className="dropdown">
                                <button className="btn btn-white dropdown-toggle shadow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {newParam.has("min_price") && newParam.has("max_price") ? `$ ${newParam.get("min_price")} - ${newParam.get("max_price")}` : `${language_data?.[authState.lang]?.common_filter} ${language_data?.[authState.lang]?.common_by} ${language_data?.[authState.lang]?.common_price}`}</button>
                                <div className="dropdown-menu pb-0 price-filter-dropdown">

                                    <form onSubmit={onChangeprice}>
                                        <div className="d-flex gap-3 mb-3">
                                            {/* min  */}
                                            <div className='form-fields-box'>
                                                <label htmlFor="" className="fw-semibold mb-1">{language_data?.[authState.lang]?.common_min} {language_data?.[authState.lang]?.common_price}</label>
                                                <input type="text"
                                                    name="min_price"
                                                    value={min_price}
                                                    // onChange={(e) => setMin_price(e.target.value.replace(/\D/, ""))}
                                                    onChange={(e) => {
                                                        NumberValidation(e.target.value) ? setMin_price(e.target.value) : console.log(e.target.value)
                                                    }}
                                                    className="form-control rounded-0" placeholder={language_data?.[authState.lang]?.common_min} />
                                            </div>
                                            {/* max */}
                                            <div className='form-fields-box'>
                                                <label htmlFor="" className="fw-semibold mb-1">{language_data?.[authState.lang]?.common_max} {language_data?.[authState.lang]?.common_price}</label>
                                                <input type="text"
                                                    name="max_price"
                                                    value={max_price}
                                                    onChange={(e) => {
                                                        NumberValidation(e.target.value) ? setMax_price(e.target.value) : console.log(e.target.value)
                                                    }}
                                                    className="form-control rounded-0" placeholder={language_data?.[authState.lang]?.common_max} />
                                            </div>
                                        </div>
                                        {/* button  */}
                                        <div>
                                            <button type='submit' className='btn btn-theme w-100 fw-semibold'>{language_data?.[authState.lang]?.common_submit}</button>
                                            <button type='submit' className='btn btn-theme w-100 fw-semibold mt-2' onChange={(e: any) => { setMax_price(''); setMin_price('') }} >{language_data?.[authState.lang]?.common_clear}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.filters ?
                        <div className="col-md-2 col-sm-3">
                            <div className='form-select-box'>
                                <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.common_filters} </label>
                                <div className="dropdown">
                                    <button className="btn btn-white dropdown-toggle shadow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {newParam.get('stock') ? capitalise(newParam.get('stock')) : newParam.get('order_status') ? `${capitalise(newParam.get('order_status'))} Status` : newParam.get('payment_status') ? `${capitalise(newParam.get('payment_status'))} Status` :  `${language_data?.[authState.lang]?.common_all} ${language_data?.[authState.lang]?.common_filters}`}
                                    </button>
                                    <ul className="dropdown-menu all-filter-wrapper">
                                        {/* <li>Filters</li> */}
                                        <li className="all-filter-wrapper-spacing" onClick={() => { handleSearch('stock', ''); handleSearch('payment_status', ''); handleSearch('order_status', '') }} role="button">
                                            <p className="dropdown-item p-0" >
                                                <span className="form-check d-flex gap-2 px-4">
                                                    <label className="form-check-label fw-bold" role="button">
                                                    {language_data?.[authState.lang]?.common_all} {language_data?.[authState.lang]?.common_status}
                                                    </label>
                                                </span>
                                            </p>
                                        </li>
                                        {filters.map((res, index) => res.active ? <Fragment key={index}>
                                            <li className="px-4 fw-bold all-filter-wrapper-spacing">{res.label} <i className={res.labelClass ? 'fa fa-circle' : ''}></i>
                                                <ul className="list-unstyled inner-list">
                                                    {res.Array.map((resp, index2) => <li onClick={() => { handleSearch(resp.query, resp.value) }} key={index2} role="button">
                                                        <p className="dropdown-item py-0 ps-0">
                                                            <span className="form-check m-0 px-0">
                                                                <label className="form-check-label" role="button">
                                                                    {resp.lableName}<i className={resp.queryValue === resp.value ? 'fa fa-check ms-1' : ''}></i>
                                                                </label>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    )}
                                                </ul>
                                            </li>
                                        </Fragment> : '')}


                                    </ul>
                                </div>
                            </div>
                        </div> : ''}

                    {props.date ?
                        <div className="col-md-3 col-sm-4">
                            <div className='form-select-box'>
                                <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.commono_start_to_end_date}</label>
                                {/* <div>
                                    <div className={`dropdown ${newParam.has('start_date') && newParam.has('end_date') ? 'btn-group' : ''}`} ref={calendarDropRef}>

                                        <button className="btn btn-white dropdown-toggle shadow-none" type="button" onClick={() => setShowCalendar(!showCalendar)}>
                                            Date Filter</button>
                                        {newParam.has('start_date') && newParam.has('end_date') ? <button type="button" className="btn btn-white shadow-none" onClick={() => { handleSearch('start_date', ''); handleSearch('end_date', ''); setShowCalendar(false) }}>
                                            x
                                        </button> : ''}
                                        <ul className={`dropdown-menu py-0  ${showCalendar ? 'show' : ''} `}>
                                            <Calendar onChange={(e: any) => dateSelect(e)} maxDate={new Date()} selectRange value={newParam.has('start_date') && newParam.has('end_date') ? [moment(Number(newParam.get('start_date'))).toDate(), moment(Number(newParam.get('end_date'))).toDate()] : new Date()} />

                                        </ul>
                                    </div>
                                </div> */}
                                <div>
                                    <div className={`dropdown ${newParam.has('start_date') && newParam.has('end_date') ? 'btn-group' : ''}`} ref={calendarDropRef}>
                                        <button className="btn btn-white dropdown-toggle shadow-none" type="button" onClick={() => setShowCalendar(!showCalendar)}
                                            title={`${newParam.has("start_date") ? moment(Number(newParam.get('start_date'))).format("DD/MM/YYYY") : ''}${newParam.has("start_date") && newParam.has("start_date") ? '-' : ""}${newParam.has("end_date") ? moment(Number(newParam.get('end_date'))).format("DD/MM/YYYY") as any : `${language_data?.[authState.lang]?.common_date} ${language_data?.[authState.lang]?.common_filter}`}`}>
                                            {`${newParam.has("start_date") ? moment(Number(newParam.get('start_date'))).format("DD/MM") : ''}${newParam.has("start_date") && newParam.has("start_date") ? '-' : ""}${newParam.has("end_date") ? moment(Number(newParam.get('end_date'))).format("DD/MM") as any : `${language_data?.[authState.lang]?.common_date} ${language_data?.[authState.lang]?.common_filter}`}`}   </button>
                                        {newParam.has('start_date') && newParam.has('end_date') ? <button type="button" className="btn btn-white shadow-none" onClick={() => { handleSearch('start_date', ''); handleSearch('end_date', ''); setShowCalendar(false) }}>
                                            x
                                        </button> : ''}
                                        <ul className={`dropdown-menu py-0  ${showCalendar ? 'show' : ''} `}>
                                            <Calendar maxDate={new Date()} onChange={(e: any) => dateSelect(e)} selectRange value={newParam.has('start_date') && newParam.has('end_date') ? [moment(Number(newParam.get('start_date'))).toDate(), moment(Number(newParam.get('end_date'))).toDate()] : null} />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                </div>
            </div>
            {/* export  */}
            <div className="col-lg-2">
                <div className='d-flex gap-3 justify-content-lg-end'>
                    <div className='download-export-box'>
                        <label className='mb-1 form-label fw-semibold'>{language_data?.[authState.lang]?.common_export_file}</label>
                        <div className="export-button">
                            <button className="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2'></i>.csv</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default React.memo(AllFilter)