import {
    ComposedChart, Area, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import commonArray from './common/commonArray';
const Graph = (props: any) => {
    return <div className="col-xl-6 px-0 px-md-2">
        <div className="common-card">
            <div className="common-card-title d-flex justify-content-between align-items-center flex-column flex-md-row">
                <h5 className='text-nowrap mb-2 mb-md-0'>{props.heading}</h5>
                <div className="btn-group">
                    {commonArray.buttonArray.map((res,index:number) => <button className={`btn btn-white ${props.Match === res.toUpperCase() ? 'active-btn' : ''}`} type="button" onClick={() => props.dashboadGraph(props.run, res.toUpperCase())} disabled={props.loading} key={index}>{res}</button>)}
                </div>
            </div>
            <div className="common-card-content">
                <ResponsiveContainer width={'100%'} height={300}>
                    <ComposedChart width={600} height={300} data={props.data}>
                        <XAxis dataKey={props.xAxis} stroke="#2f4050" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey={props.yAxis} fill="#1AA7DE" stroke="#2f4050" />
                        <Bar dataKey={props.xAxis} barSize={50} fill="#1AA7DE" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>

}
export default Graph;
