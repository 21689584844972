import React, { Fragment, useContext, useRef, useState } from 'react';
import moment from 'moment'
import Spinner from './spinner';
import { GlobalContext } from '../../context/Provider';
const DownloadFileModal = ({ exportData }: any) => {
    const { authState,language_data} = useContext(GlobalContext)

    const [startDate, setStartDate] = useState<number>(moment().subtract(10, 'days').toDate().getTime())
    const [endDate, setEndDate] = useState<number>(moment().toDate().getTime())
    const btnRef = useRef(null) as React.MutableRefObject<any>
    const [loading, setLoading] = useState(false)

    const exportNow = async () => {
        setLoading(true)
        await exportData(startDate, endDate)
        setLoading(false)
        btnRef?.current.click()
    }
    console.log('running');
    
    return (
        <Fragment>
            <div className="modal fade" id="fileDownloadModal" tabIndex={-1} aria-labelledby="fileDownloadModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header position-relative">
                            {/* Title  */}
                            <h5 className="modal-title fs-5 d-block w-100 text-center" id="exampleModalLabel">{language_data?.[authState.lang]?.common_download} {language_data?.[authState.lang]?.common_records}</h5>
                            <div className='close-modal-button'>
                                <button type="button" ref={btnRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body form-fields-box">
                            <div className="row">
                                {/* from Date  */}
                                <div className="col-md-6">
                                    <label htmlFor="" className='fw-semibold'>{language_data?.[authState.lang]?.common_from} {language_data?.[authState.lang]?.common_date}</label>
                                    <input type="date" name='start_date' className='form-control rounded-0' value={moment(startDate).format('YYYY-MM-DD')} onChange={(e) => setStartDate(e.target.valueAsNumber)} />
                                </div>
                                {/* To date  */}
                                <div className="col-md-6">
                                    <label htmlFor="" className='fw-semibold'>{language_data?.[authState.lang]?.common_to} {language_data?.[authState.lang]?.common_date}</label>
                                    <input type="date" name='start_date' className='form-control rounded-0' value={moment(endDate).format('YYYY-MM-DD')} onChange={(e) => setEndDate(e.target.valueAsNumber)} />
                                </div>
                            </div>
                        </div>
                        {/* Downoad Button  */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white bg-danger text-white m-0 me-3" data-bs-dismiss="modal" disabled={loading}>{language_data?.[authState.lang]?.common_cancel}</button>
                            <button className='btn btn-theme m-0' type='button' onClick={exportNow} disabled={loading}>{loading ? <Spinner color={"text-white"} /> : `${language_data?.[authState.lang]?.common_download}`}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default React.memo(DownloadFileModal);