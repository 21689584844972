const FIREBASE_API_KEY = "AIzaSyAUtsGb0u1m7yn7HNk4XkJ3HDSqpLI_FOw"
const FIREBASE_AUTH_DOMAIN = "readyecommerce-420ab.firebaseapp.com"
const FIREBASE_PROJECT_ID = "readyecommerce-420ab"
const FIREBASE_STORAGE_BUCKET = "readyecommerce-420ab.appspot.com"
const FIREBASE_MESSAGE_SENDER_ID = "12452441274"
const FIREBASE_APP_ID = "1:12452441274:web:93720fa99b92d838b06683"
const FIREBASE_MEASURMENT_ID = "G-VDE2PNG093"
const FIREBASE_VAP_ID_KEY = "BABw3zBcwEUbrjIXw4ASiDz0EWe8xBV2geSQoR2KNZq13fU3_2o7oS60IsJc5_EIoBKTn2DcuvFMEkI2fjmdpJo"

// NEXT_PUBLIC_FIREBASE_API_KEY="AIzaSyAUtsGb0u1m7yn7HNk4XkJ3HDSqpLI_FOw"
// NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN="readyecommerce-420ab.firebaseapp.com"
// NEXT_PUBLIC_FIREBASE_PROJECT_ID="readyecommerce-420ab"
// NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET="readyecommerce-420ab.appspot.com"
// NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID="12452441274"
// NEXT_PUBLIC_FIREBASE_APP_ID="1:12452441274:web:93720fa99b92d838b06683"
// NEXT_PUBLIC_FIREBASE_MEASURMENT_ID="G-VDE2PNG093"
// NEXT_PUBLIC_FIREBASE_VAP_ID_KEY="BABw3zBcwEUbrjIXw4ASiDz0EWe8xBV2geSQoR2KNZq13fU3_2o7oS60IsJc5_EIoBKTn2DcuvFMEkI2fjmdpJo"
export default {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGE_SENDER_ID,
    FIREBASE_APP_ID,
    FIREBASE_MEASURMENT_ID,
    FIREBASE_VAP_ID_KEY,
}