import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import defaultImage from '../../assets/images/pages/defaultImage.jpg'
import { Link, useLocation, useMatch } from 'react-router-dom'
import DownloadFileModal from '../../components/common/download-file-modal'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import PaginationLayout from '../../components/common/PaginationLayout'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import Spinner from '../../components/common/spinner'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import { numberWithCommas } from '../../utils/validations'
import { orderListType } from './orderInterface'
import OrderStatus from '../../components/order/OrderStatus'
import AllFilter from '../../components/common/AllFilter'
import Test from '../../utils/test'
import COPY from "../../../src/assets/images/copy.png"
import { toast } from 'react-toastify'



const OrderListing = () => {
    let Limit = 10
    const location = useLocation()
    const { authState, loading, setLoading, language_data, authDispatch } = useContext(GlobalContext)
    const match = useMatch('/orders/:page')
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' }
    ]
    const newParam = new URLSearchParams(location.search);

    const [orderL, setOrderL] = useState({
        total_count: 0,
        data: []
    } as orderListType)
    const orderListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Order.getOrderList(Number(match?.params.page) - 1, Limit, newParam.toString())).data
            setOrderL({
                total_count: res.total_count,
                data: res.data
            })
        } catch (err: any) {
            console.log("err", err.response.body.error_description);
            setOrderL({
                total_count: 0,
                data: []
            })
            handleError(err, 'active', authDispatch);
            // (err)
        } finally {
            setLoading(false)
        }
    }
    const copyText = (id: string, name: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${name} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Order.export(startDate, endDate, '&order_status=ALL')
            const data = apiRes.data.data
            console.log('data', data);
            const rows = [
                [
                    // "Sr.",
                    "Order ID",
                    "Customer Detail",
                    "Seller Detail",
                    "Product ID",
                    "Product Name",
                    "Order Price",
                    "Coupon/Discount",
                    "Order Status",
                    "Earning",
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return (
                        rows.push([
                            // index,
                            res?.order_id,
                            res?.user_id?.name,
                            res?.seller_id?.name,
                            res?.product_id?.prodct_id,
                            res?.product_id?.name,
                            numberWithCommas(res?.total_price),
                            res?.coupon_discount,
                            res?.order_status,
                            res?.total_earnings
                        ]))
                })
            }
            console.log(rows);
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `user_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (err: any) {
            console.log(err.response.body.error_description);
            handleError(err, 'active', authDispatch);
        }

    }

    useEffect(() => {
        orderListing()
    }, [match?.params.page, newParam.get("search"), newParam.get("stock"), newParam.get("start_date"), newParam.get("end_date"), newParam.get('payment_status'), newParam.get("order_status"), newParam.get("product_id"), newParam.get("min_price"), newParam.get("max_price")])
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className=' page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        <AllFilter stock="active" orderStatus="active" refund="active" date="active" filters="active" />
                        <div className="common-card">
                            <div className="common-card-title">
                                <h5>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_listing} {language_data?.[authState.lang]?.common_deatil}</h5>
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-4 text-center'>
                                    {loading ? <Spinner color={'text-success'} /> : <table className="table table-striped align-middle">
                                        <thead className=''>
                                            <tr>
                                                <th>{language_data?.[authState.lang]?.common_sr_no}.</th>
                                                <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_id}</th>
                                                <th>{language_data?.[authState.lang]?.common_customer} {language_data?.[authState.lang]?.common_name}</th>
                                                {/* <th>Seller Detail</th> */}
                                                <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}</th>
                                                <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_name}</th>
                                                <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_price}</th>
                                                <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_status}</th>
                                                {/* <th>Order Quantity</th> */}
                                                <th title='Coupon/discount Price' className='text-center'>{language_data?.[authState.lang]?.common_coupon} {language_data?.[authState.lang]?.common_discount}</th>
                                                {newParam.has('stock') ? <th>{language_data?.[authState.lang]?.common_quantity}</th> : ''}
                                                <th>{language_data?.[authState.lang]?.common_earning}</th>
                                                <th>{language_data?.[authState.lang]?.common_action}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(orderL.data) && orderL.data.length ? orderL?.data?.map((res, index: number) => {
                                                return (
                                                    <tr key={res._id}>
                                                        <td>{match?.params.page === "0"
                                                            ? index + 1
                                                            : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res.order_id ? res.order_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.order_id, "OrderId")} role="button" data-toggle="tooltip" title={res.order_id} /></td>
                                                        <td className='product-image-table'>
                                                            {/* <Test size="small" filename="https://api.unsplash.com/search/photos?query=minimal"/> */}
                                                            <img src={res?.user_id?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.user_id?.profile_pic}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                            <span>{res?.user_id?.name ? res?.user_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                        </td>
                                                        {/* <td className='product-image-table'>
                                                            <img src={res?.seller_id?.image ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.seller_id.image}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                            <span>{res?.seller_id?.name ? res?.seller_id?.name : "Not Avaiable"}</span>
                                                        </td> */}
                                                        <td>{res?.product_id?.prodct_id ? res?.product_id?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res?.product_id?.prodct_id, "ProductId")} role="button" data-toggle="tooltip" title={`${res?.product_id?.prodct_id}`} /></td>
                                                        <td className='product-image-table d-flex '>
                                                            <span className=''>
                                                                {/* <Test size="small" filename={res?.product_id?.images[0]} /> */}
                                                                <img src={res?.product_id?.images[0] ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.product_id.images[0]}` : defaultImage} alt={res.product_id.images[0]} className='me-2' />
                                                            </span>
                                                            <span className='d-flex flex-column justify-content-start'>
                                                                <p>{res?.product_id?.category_id?.name ? res?.product_id?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                                <p>{res?.product_id?.name?.length > 18 ? <Fragment>{res?.product_id?.name?.slice(0, 18)}...</Fragment> : res?.product_id?.name ? res?.product_id?.name : "Not Available"}</p>
                                                            </span>
                                                        </td>
                                                        <td><b>&#36;</b> {res?.total_price ? numberWithCommas(res?.total_price) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>
                                                            <OrderStatus {...res} />
                                                        </td>
                                                        {/* <td>{res?.quantity?res?.quantity:0}</td> */}
                                                        <td className='text-center'>${res?.coupon_discount ? res?.coupon_discount : 0}</td>
                                                        {newParam.has('stock') ? <td>{res?.product_quantity ? res?.product_quantity : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td> : ''}
                                                        <td><b>&#36;</b> {res?.total_earnings ? numberWithCommas(res?.total_earnings) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/order/${res?._id}`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr className='text-center'><td colSpan={newParam.has('stock') ? 10 : 9}>{language_data?.[authState.lang]?.common_no_data_found}</td></tr>}



                                        </tbody>
                                    </table>}
                                </div>
                                {/* pagination  */}
                                <PaginationLayout
                                    data={orderL.data}
                                    count={orderL.total_count}
                                    limit={Number(Limit)}
                                    page={Number(match?.params.page)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </Fragment>
    )
}
export default OrderListing;