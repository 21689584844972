import React, { useEffect } from "react"
import { Fragment, useContext, useRef, useState } from 'react';
import { toast } from "react-toastify"
import henceforthValidations from "../../utils/henceforthValidations";
import Spinner from "../common/spinner";
import { GlobalContext, handleError } from '../../context/Provider';
import { NumberValidation } from '../../utils/validations';
import henceforthApi from "../../utils/henceforthApi";
import { useNavigate } from "react-router-dom";


const VerifyOtp = ({ verifyCallback, resendOtpCallback, onCloseModal }: any) => {
    const { language_data, authState, toastMessage, authDispatch } = useContext(GlobalContext)
    var countdownTimer = null as any;
    const [otp, setOtp] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [timerCount, setTimerCount] = React.useState(0)
    const navigate = useNavigate();

    const [otpErr, setOtpErr] = useState('')
    const [input, setInput] = useState({
        input1: "",
        input2: "",
        input3: "",
        input4: ""
    })
    function onhandleChange(evt: any) {
        const value = evt.target.value;
        if (NumberValidation(value)) {
            setOtpErr('')
            setInput({
                ...input,
                [evt.target.name]: value
            });
            Onfocus(evt.target.value)
        }
    }

    function onhandleChange1(evt: any) {
        const value = evt.target.value;
        if (NumberValidation(value)) {
            setOtpErr('')
            setInput({
                ...input,
                [evt.target.name]: value
            });
            Onfocus1(evt.target.value)
        }
    }

    function onhandleChange2(evt: any) {
        const value = evt.target.value;
        if (NumberValidation(value)) {
            setOtpErr('')
            setInput({
                ...input,
                [evt.target.name]: value
            });
            Onfocus2(evt.target.value)
        }
    }

    function onhandleChange3(evt: any) {
        const value = evt.target.value;
        if (NumberValidation(value)) {
            setOtpErr('')
            setInput({
                ...input,
                [evt.target.name]: value
            });
            Onfocus3(evt.target.value)
        }
    }

    let inputRef4 = useRef(null) as React.MutableRefObject<any>
    let inputRef5 = useRef(null) as React.MutableRefObject<any>
    let inputRef6 = useRef(null) as React.MutableRefObject<any>
    let inputRef7 = useRef(null) as React.MutableRefObject<any>

    const Onfocus = (value: string) => {
        if (value) {
            inputRef5.current.focus()
        }
    }
    const Onfocus1 = (value: string) => {
        if (value) {
            inputRef6.current.focus()
        }
        else {
            inputRef4.current.focus()
        }
    }
    const Onfocus2 = (value: string) => {
        if (value) {
            inputRef7.current.focus()
        }
        else {
            inputRef5.current.focus()
        }
    }
    const Onfocus3 = (value: string) => {
        if (value) {
            inputRef7.current.focus()
        }
        else {
            inputRef6.current.focus()
        }
    }


    // console.log(input);
    const queryParams = new URLSearchParams(window.location.search)
    const term = queryParams.get("unicode")
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // console.log(term);
        const { input1, input2, input3, input4 } = input;
        setOtp(`${input1}${input2}${input3}${input4}`);
        setLoading(true)

        try {
            setLoading(true)
            let otpRes = await verifyCallback(Number(`${input1}${input2}${input3}${input4}`))

            // let otpRes = await henceforthApi.Auth.checkOtp({
            //     unique_code: term,
            //     otp: Number(`${input1}${input2}${input3}${input4}`),
            //     language: "ENGLISH"
            // })
            toastMessage(otpRes.data.message)
            navigate({
                pathname: '/password/reset',
                search: `?unicode=${otpRes.data.unique_code}`,
            })
            setInput({
                ...input,
                input1: "",
                input2: "",
                input3: "",
                input4: ""
            })
            inputRef4.current.focus()
        } catch (err) {
            setInput({
                ...input,
                input1: "",
                input2: "",
                input3: "",
                input4: ""
            })
            setOtpErr('error')
            handleError(err, 'active', authDispatch);
            inputRef4.current.focus()
        } finally {
            setLoading(false)
        }
    }
    var countdownTimer = null as any;
    const initialiseInterval = () => {
        var _second = 30
        function timer() {
            if (_second < 1) {
                clearInterval(countdownTimer);
                countdownTimer = null
                return;
            } else {
                _second -= 1
            }
            setTimerCount(_second)
        }
        countdownTimer = setInterval(() => timer(), 1000);
    }



    // const initialiseInterval = () => {
    //     var _second = 30
    //     function timer() {
    //         if (_second < 1) {
    //             clearInterval(countdownTimer);
    //             countdownTimer = null
    //             return;
    //         } else {
    //             _second -= 1
    //         }
    //         setTimerCount(_second)
    //     }
    //     countdownTimer = setInterval(() => timer(), 1000);
    // }
    // const verify = async () => {
    //     if (!otp) return toast.warn("Please enter your OTP")
    //     if (otp.length < 4) return toast.warn("Please enter Correct OTP")
    //     try {
    //         setLoading(true)
    //         await verifyCallback(Number(otp))

    //     } catch (error: any) {
    //         toast.error(error?.response?.body?.error_description)
    //     } finally {
    //         setLoading(false)
    //     }
    // }
    const resendOtpNow = async () => {
        await resendOtpCallback()
        initialiseInterval()
    }


    useEffect(() => {
        // eslint-disable-next-line
        initialiseInterval()
    }, [])




    return <div className="modal fade show" id="VerifyEmailModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="fixedPriceModalLabel" aria-hidden="true" role='dialog' style={{ display: "block", backgroundColor: 'rgba(0,0,0,0.5)' }} >
        <div className="modal-dialog modal-dialog-centered">
            <div className="container">
                {/* onSubmit={(e) => { e.preventDefault(); verify() }}> */}
                <form className="row" onSubmit={handleSubmit}>
                    <div className="col-md-8 m-auto">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0">

                                <h5 className="w-100 text-center text-dark fw-bold fs-4 mt-2 d-flex justify-content-center " id="fixedPriceModalLabel">
                                    <span className="text-gradient">Verify Email</span>
                                </h5>
                                {/* <button type="button" className="btn-close" style={{ position: 'absolute', right: '8px', top: ' 12px' }} data-bs-dismiss="modal" onClick={() => onCloseModal(false)}></button> */}
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: '8px', top: ' 12px' }} onClick={() => onCloseModal(false)}></button>
                            </div>
                            <div className="modal-body pt-1 pb-0 p-4">
                                <div className="position-relative mt-3">
                                    <div className="input-group ">
                                        {/* <h3 className="">Enter </h3> */}
                                        {/* <input type="text" className="form-control border-pink pe-5 mb-1" placeholder="Enter OTP " value={otp}  onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setOtp(e.target.value) : console.log("wrong keywork")}maxLength={4} /> */}
                                        <div className='form-fields-box d-flex gap-3 otp-verification-inputs justify-content-center mx-auto text-center'>
                                            <input
                                                type="text"
                                                className={`text-center form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                                                placeholder=""
                                                tabIndex={1}
                                                name='input1'
                                                maxLength={1}
                                                ref={inputRef4}
                                                value={input.input1}
                                                // onChange={(e) => henceforthValidations.NumberValidation(e.target.value) ? setOtp(e.target.value) : console.log("wrong keywork")}
                                                onChange={onhandleChange}
                                                required />
                                            <input
                                                type="text"
                                                className={`text-center form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                                                ref={inputRef5}
                                                tabIndex={2}
                                                name='input2'
                                                value={input.input2}
                                                maxLength={1}
                                                onChange={onhandleChange1}
                                                placeholder=""
                                                required />
                                            <input
                                                type="text"
                                                className={`text-center form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                                                ref={inputRef6}
                                                tabIndex={3}
                                                name='input3'
                                                value={input.input3}
                                                maxLength={1}
                                                onChange={onhandleChange2}
                                                placeholder=""
                                                required />
                                            <input
                                                type="text"
                                                className={`text-center form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                                                tabIndex={4}
                                                ref={inputRef7}
                                                name='input4'
                                                value={input.input4}
                                                maxLength={1}
                                                onChange={onhandleChange3}
                                                placeholder=""
                                                required />
                                        </div>
                                    </div>
                                    <p className="fs-10 text-dark mb-0 text-center mt-3">Didn't receive code ?</p>
                                    <p className="mb-0 text-center mb-3"><span className="text-theme fs-12 fw-semi-bold ms-1 cursor-pointer" > {timerCount ? `Resend Code in: ${timerCount}` : <b className="cursor-pointer" onClick={() => resendOtpNow()} role="button">{language_data?.[authState.lang]?.common_resend} {language_data?.[authState.lang]?.common_code}</b>} </span></p>

                                </div>
                            </div>
                            <div className="modal-footer border-0 pb-4 pt-2 p-4">
                                <button type="submit" className="btn btn-theme shadow-none w-100 m-auto rounded-pill mb-2 text-capitalize" disabled={loading}>{loading ? <Spinner color="text-success" /> : `Verify Now`}</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

}
export default VerifyOtp