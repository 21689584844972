import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import commonArray from '../../components/common/commonArray';
import BreadCrumb from '../../components/common/BreadCrumb';
import { GlobalContext, handleError } from '../../context/Provider';
import profile_img1 from '../../assets/images/pages/defaultImage.jpg';
import '../../assets/styles/pages.scss';
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../../components/common/spinner';
import Swal from 'sweetalert2';
import moment from 'moment';
import { numberWithCommas } from '../../utils/validations';
import { stateType } from './productInterface';
import Ratings from '../../components/common/Ratings';

const ViewProductDetail = () => {
    const { authState, language_data, authDispatch } = useContext(GlobalContext)
    const match = useMatch('/product/:id')
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: ``, active: 'not-allowed' },
    ]

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [variants, setVariants] = useState([] as Array<any>)
    const [highlights, setHighlights] = useState([])
    const [services, setServices] = useState([] as Array<any>)
    const [specification, setspecification] = useState([] as Array<any>)
    const [faq, setFaq] = useState([] as Array<any>)
    const [delivery, setDelivery] = useState([] as Array<any>)
    const [checked, setChecked] = useState<boolean>(false);
    const [state, setState] = useState({
        _id: '',
        name: '',
        description: '',
        product_type: '',
        added_by: {
            _id: '',
            name: ''
        },
        category_id: {
            _id: '',
            name: ''
        },
        subcategory_id: {
            _id: '',
            name: ''
        },
        sub_subcategory_id: {
            _id: '',
            name: ''
        },
        brand_id: {
            _id: '',
            name: ''
        },

        images: [],
        quantity: '',
        price: 0,
        prodct_id: '',
        discount_percantage: '',
        tax_percentage: 0,
        discount: '',
        discount_price: 0,
        total_reviews: '',
        total_ratings: '',
        ratings: [],
        is_delivery_available: false
    } as stateType)
    const [showMore, setShowMore] = useState(false)

    const productDetails = async () => {
        setLoading(true)
        try {
            let productRes = (await henceforthApi.Product.getProductDetails(match?.params.id)).data
            setState(productRes)
        } catch (err: any) {
            console.log(err.response.body.error_description);
            handleError(err, '', authDispatch);
        }
        finally {
            setLoading(false)
        }
    }
    const toggleDelivery = async (checked: boolean) => {
        const items = {
            _id: match?.params.id,
            is_delivery_available: checked,
            language: 'ENGLISH'

        }
        try {
            let res = await henceforthApi.Product.editProduct(items)
            productDetails()
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        productDetails()
        commonArray.productOtherDetails.map(res => productOtherDetails(res))
    }, [match?.params.id])
    const productOtherDetails = async (apiUrl: string) => {
        setLoading(true)
        try {
            let apiRes = (await henceforthApi.Product.getProductDetailsAllDynamics(apiUrl, apiUrl === commonArray.productOtherDetails[5] ? match?.params.id as string : '', apiUrl !== commonArray.productOtherDetails[5] ? match?.params.id as string : '')).data.data
            // console.log(`${apiUrl}`, apiRes);
            if (apiUrl === commonArray.productOtherDetails[0]) setHighlights(apiRes)
            else if (apiUrl === commonArray.productOtherDetails[1]) setspecification(apiRes)
            else if (apiUrl === commonArray.productOtherDetails[2]) setVariants(apiRes)
            else if (apiUrl === commonArray.productOtherDetails[3]) setFaq(apiRes.map((res: any) => { return { ...res, readMore: false } }))
            else if (apiUrl === commonArray.productOtherDetails[4]) setServices(apiRes)
            else if (apiUrl === commonArray.productOtherDetails[5]) setDelivery(apiRes)

            setLoading(false)
        } catch (err: any) {
            console.log(err.response.body.error_description);
            handleError(err, '', authDispatch);
        }
    }
    const deleteSpecificDetail = async (select: string, id: any) => {
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure} ${language_data?.[authState.lang]?.common_questionmark}`,
            text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${language_data?.[authState.lang]?.common_delete} ${language_data?.[authState.lang]?.common_this} ${select}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: `${language_data?.[authState.lang]?.common_cancel}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes} ${language_data?.[authState.lang]?.common_delete} ${language_data?.[authState.lang]?.common_it}`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (commonArray.productOtherDetails.includes(select)) await henceforthApi.Product.deleteProductDetailsDynamically(select, id)
                    else if (select === "product") {
                        await henceforthApi.Product.editProduct({
                            _id: id,
                            is_deleted: true
                        })
                        navigate('/products/1')
                    }
                    productOtherDetails(select)
                    Swal.fire({
                        icon: 'success',
                        title: `${language_data?.[authState.lang]?.common_deleted}`,
                        text: `${language_data?.[authState.lang]?.common_your} ${select} ${language_data?.[authState.lang]?.common_has_been_deleted}.`,
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false
                    }
                    )
                } catch (err: any) {
                    handleError(err, 'active', authDispatch);
                    console.log(err.response.body.error_description);
                }
            }
        })
    }
    const faqReadMore = (id: number, showHide: boolean) => {
        faq[id].readMore = showHide
        setFaq([...faq])
    }
    console.log(faq);

    return (
        <Fragment>

            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            {loading ? <div className='vh-100 d-flex justify-content-center py-5'>
                <Spinner color={"text-success"} />
            </div> :
                <div className='page-spacing'>
                    <section className='product-detail'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 px-0">
                                    {/* Title  */}
                                    {state.is_delivery_available ? '' : delivery.length === 0 ? <div className="alert alert-warning" role="alert">
                                        {language_data?.[authState.lang]?.common_please_add_delivery_if_you_want_to_show_your_product_in_marketplace}.
                                    </div> : ''}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
                                                <h5 className='mb-2 m-0 mb-md-0'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_deatil}</h5>
                                                <div className="btn-group gap-2 flex-wrap flex-sm-row">
                                                    <Link to={`/product/clone/${match?.params.id}`} className="btn btn-white btn-sm rounded-0"> <i className="fa fa-clone me-1"></i>{language_data?.[authState.lang]?.common_clone} {language_data?.[authState.lang]?.common_product}</Link>
                                                    <button className="btn btn-white btn-sm bg-danger text-white border-danger rounded-0" onClick={() => deleteSpecificDetail("product", match?.params.id)}> <i className="fa fa-trash me-1" ></i>{language_data?.[authState.lang]?.common_delete}</button>
                                                    <Link to={`/product/${match?.params.id}/edit`} className="btn btn-theme btn-sm rounded-0"> <i className="fa fa-pencil me-1"></i>{language_data?.[authState.lang]?.common_edit}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                                        <div className="carousel-indicators">
                                                            {Array.isArray(state.images) ? state.images.map((res: any, index: number) => {
                                                                return (
                                                                    <button type="button" key={res} data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={`${index === 0 ? "active" : ""} ${state.images.length === 1 ? "d-none" : ""}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                                                                )
                                                            }) : ""}
                                                        </div>
                                                        <div className="carousel-inner product-images">
                                                            {Array.isArray(state.images) ? state.images.map((res: any, index: number) => {
                                                                return (
                                                                    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                                                                        <img src={`${henceforthApi?.API_FILE_ROOT_ORIGINAL}${res}`} className="d-block w-100" alt={res} />
                                                                    </div>
                                                                )
                                                            }) : <div className="carousel-item active">
                                                                <img src={profile_img1} className="d-block w-100" alt="img" />
                                                            </div>}
                                                        </div>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">{language_data?.[authState.lang]?.common_previous}</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">{language_data?.[authState.lang]?.common_next}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* Product detail  */}
                                                <div className="col-md-7">
                                                    <div className="product-detail-box">
                                                        <h2 className='fw-bolder text-capitalize'>{state?.name ? state?.name : `${language_data?.[authState.lang]?.common_not_available}`}</h2>
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <h2 className='fw-semibold m-0'>&#36; {state?.price ? numberWithCommas(state?.price) : "00.00"}</h2><small>{language_data?.[authState.lang]?.common_including_tax}</small>
                                                        </div>
                                                        <div className="divider my-3"></div>
                                                        <div className='mb-3'>
                                                            <h4 className='fw-bolder'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_description}</h4>
                                                            <p className='fw-semibold text-capitalize'>{state?.description ? `${state?.description.length > 1000 ? !showMore ? `${state?.description?.slice(1000)}` : state?.description : state?.description}` : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                            {state?.description?.length > 1000 ? <p className='ReadMore' role="button" onClick={() => setShowMore(!showMore)}>{!showMore ? `${language_data?.[authState.lang]?.common_read_more}` : `${language_data?.[authState.lang]?.common_read_less}`} </p> : ''}
                                                        </div>
                                                        <ul className='list-unstyled product-detail-list'>
                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}:</b><span>{state?.prodct_id ? state?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>

                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_category_level}- {language_data?.[authState.lang]?._1}:</b><span>{state?.category_id?.name ? state?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>

                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_category_level}- {language_data?.[authState.lang]?._2}:</b><span>{state?.subcategory_id ? state?.subcategory_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                            {state?.sub_subcategory_id &&
                                                                <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_category_level} {language_data?.[authState.lang]?._3}:</b><span>{state?.sub_subcategory_id?.name}</span></li>}

                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_brand}:</b><span>{state?.brand_id ? state?.brand_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>

                                                            <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_discount}:</b><span>{state?.discount_percantage ? `${state?.discount_percantage}` : "0"}%</span></li>
                                                            <li className="d-flex flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_tax}:</b><span>{state?.tax_percentage ? `${state?.tax_percentage}` : "0"}%</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="divider my-3"></div>
                                                    {/* highlights  */}
                                                    <div className="product-highlights-box">
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='fw-bolder mb-0'>{language_data?.[authState.lang]?.common_highlights}</h5>
                                                            <Link to="highlight/add" className="btn btn-theme btn-sm action-btn action-add-button"> <i className="fa fa-plus"></i></Link>
                                                        </div>
                                                        <ul className='product-detail-list ps-0 mt-3 add-product-list-spacing list-unstyled'>
                                                            {Array.isArray(highlights) && highlights.length ? highlights.map((res: any) =>
                                                                <li key={res._id} className="d-flex justify-content-sm-between align-items-sm-start flex-column flex-sm-row gap-2">
                                                                    <span className='d-inline-flex'><i className="fa fa-list fs-5 align-middle me-2" aria-hidden="true"></i>{res.content}</span>
                                                                    <ul className='list-unstyled d-flex gap-2'>
                                                                        <li className='mb-0'>
                                                                            <Link to={`highlight/${res._id}/edit?content=${res.content}`} className="btn btn-sm action-btn action-edit-button">
                                                                                <i className='fa fa-pencil' role="button" ></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <button className='btn btn-sm action-btn action-delete-button'> <i className='fa fa-trash text-danger' role="button" onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[0], res._id)}></i></button>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            ) : `${language_data?.[authState.lang]?.common_not_available}`}
                                                        </ul>
                                                    </div>
                                                    <div className="divider my-3"></div>
                                                    {/* Specifications  */}
                                                    <div className="product-highlights-box">
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='fw-bolder'>{language_data?.[authState.lang]?.common_specifications}</h5>
                                                            <Link to={`specification/add`} className="btn btn-theme btn-sm action-btn action-add-button"> <i className="fa fa-plus"></i></Link>
                                                        </div>
                                                        <ul className='list-unstyled product-detail-list mt-3 add-product-list-spacing'>
                                                            {Array.isArray(specification) && specification.length ? specification.map((res: any) => {
                                                                return (
                                                                    <li className="d-flex justify-content-sm-between align-items-sm-start flex-column flex-sm-row " key={res._id}>
                                                                        <div className='d-flex gap-2 gap-lg-4 justify-lg-between align-items-lg-center flex-column flex-lg-row'>
                                                                            <span style={{ width: '120px' }} className='fw-bold text-capitalize text-truncate text-nowrap'><i className="fa fa-list fs-5 align-middle me-2 d-none d-sm-inline-flex" aria-hidden="true"></i>{res.key}:</span>
                                                                            <span className='flex-grow-1 text-capitalize'>{res.value}</span>
                                                                        </div>

                                                                        <ul className='list-unstyled d-flex gap-2'>
                                                                            <li className='mb-0'> <Link to={`specification/${res._id}/edit?key=${res.key}&value=${res.value}`} className="btn btn-sm action-btn action-edit-button"><i className='fa fa-pencil' role="button"></i></Link></li>
                                                                            <li className='mb-0'>
                                                                                <button className='btn btn-sm action-btn action-delete-button'><i className='fa fa-trash text-danger' role="button" onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[1], res._id)}></i></button></li>
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            }) : `${language_data?.[authState.lang]?.common_not_available}`}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* Product Variants  */}
                                <div className="col-md-6 mb-3">
                                    <div className="common-card h-100">
                                        <div className="common-card-title d-flex justify-content-between align-items-center">
                                            <h5 className='fw-bolder'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_variations}</h5>
                                            <div>
                                                <Link to={`/product/${match?.params.id}/variant/add`} className="btn btn-theme btn-sm action-btn action-add-button"> <i className="fa fa-plus"></i></Link>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            <div className='data-list-table table-responsive mb-3'>
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>{language_data?.[authState.lang]?.common_sr_no}.</th>
                                                            <th>{language_data?.[authState.lang]?.common_name}</th>
                                                            <th>{language_data?.[authState.lang]?.common_images}</th>
                                                            <th>{language_data?.[authState.lang]?.common_action}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(variants) && variants.length ?
                                                            variants.map((res: any, index) => {
                                                                return (
                                                                    <tr key={res?._id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>
                                                                            <Link className='text-dark text-decoration-none' to={`/product/${res?.product_id}`}>
                                                                                {res?.product_name}</Link>
                                                                        </td>
                                                                        <td>{res?.product_images.length ? <img src={`${henceforthApi.API_FILE_ROOT_SMALL}${res?.product_images[0]}`} alt='' height={30} width={30} key={index} /> : ''}</td>
                                                                        <td>
                                                                            <div className="btn-group gap-2">
                                                                                <button type='button' className='btn btn-sm action-btn action-delete-button' onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[2], res._id)}> <i className="fa fa-trash text-danger"></i></button>
                                                                                {/* <Link className="btn btn-sm action-btn action-edit-button" to={`variant/${res._id}/edit`}> <i className="fa fa-pencil"></i></Link> */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }) : <tr className='text-center'><td colSpan={4}> {language_data?.[authState.lang]?.common_not_available}</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* services  */}
                                <div className="col-md-6 mb-3">
                                    <div className="common-card h-100">
                                        <div className="common-card-title">

                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h5 className='fw-bolder'>{language_data?.[authState.lang]?.common_services}</h5>
                                                <Link to={`/product/${match?.params.id}/services/add`} className="btn btn-theme btn-sm action-btn action-add-button"> <i className="fa fa-plus"></i></Link>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            <ul className='product-detail-list ps-0 list-unstyled add-product-list-spacing'>
                                                {Array.isArray(services) && services.length ? services.map((res: any) =>
                                                    <li className='text-capitalize d-flex justify-content-sm-between align-items-sm-start flex-column flex-sm-row gap-2' key={res._id}>
                                                        <span><i className="fa fa-list fs-5 align-middle me-2" aria-hidden="true"></i> {res.content}</span>

                                                        <ul className='list-unstyled d-flex gap-2'>
                                                            <li className='mb-0'>
                                                                <Link to={`/product/${match?.params.id}/services/edit/${res._id}/?service=${res.content}`} className="btn btn-sm action-btn action-edit-button"><i className='fa fa-pencil' role="button"></i></Link>

                                                            </li>
                                                            <li className='mb-0'>
                                                                <button className='btn btn-sm action-btn action-delete-button'>
                                                                    <i className='fa fa-trash text-danger' role="button" onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[4], res._id)}></i>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </li>)
                                                    : <li>{language_data?.[authState.lang]?.common_not_available}</li>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Delivery  */}
                                <div className="col-md-12 mb-3">
                                    <div className="common-card h-100">
                                        <div className="common-card-title d-flex justify-content-between align-items-center">
                                            <h5 className='fw-bolder'>{language_data?.[authState.lang]?.common_delivery}</h5>
                                            <div className='d-flex gap-5'>
                                                <div className="form-check form-switch form-check-reverse  mt-1">
                                                    <input className="form-check-input fs-5 " checked={state.is_delivery_available} onChange={(e) => toggleDelivery(e.target.checked)} type="checkbox" value="" id="flexSwitchCheckReverse" />
                                                    <label className="form-check-label mt-1 fw-bolder" htmlFor="flexSwitchCheckReverse">
                                                        {language_data?.[authState.lang]?.common_delivery_all_over_world}
                                                    </label>
                                                </div>
                                                <Link to={`delivery/add`} className="btn btn-theme btn-sm action-btn action-add-button"> <i className="fa fa-plus"></i></Link>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            <div className='data-list-table table-responsive mb-3'>
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>{language_data?.[authState.lang]?.common_sr_no}.</th>
                                                            <th>{language_data?.[authState.lang]?.common_radius}</th>
                                                            <th>{language_data?.[authState.lang]?.common_location}</th>
                                                            <th>{language_data?.[authState.lang]?.common_delivery} {language_data?.[authState.lang]?.common_time}</th>
                                                            <th>{language_data?.[authState.lang]?.common_action}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(delivery) && delivery.length ? delivery.map((res: any, index: number) => <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{res?.radius ?? `${language_data?.[authState.lang]?.common_not_available}`} {res?.units ?? `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                            <td>{res?.address ?? `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                            <td>{res?.delivery_time ?? `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    <button type='button' className='btn btn-sm action-btn action-delete-button' onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[5], res?._id)}> <i className="fa fa-trash text-danger"></i></button>
                                                                    <Link className="btn btn-sm action-btn action-edit-button" to={`/product/${match?.params.id}/delivery/edit/${res?._id}`}> <i className="fa fa-pencil"></i></Link>
                                                                </div>
                                                            </td>
                                                        </tr>) : <tr className='text-center'><td colSpan={5}>{language_data?.[authState.lang]?.common_not_available}</td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Ratings  */}
                            <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <Ratings ratingArray={state?.ratings} />
                                </div>
                                {/* Faq  */}
                                <div className="col-lg-6 mb-3">
                                    <div className="common-card h-100 product-faq-box">
                                        <div className="common-card-title">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h5 className='fw-bolder'>{language_data?.[authState.lang]?.common_faq}</h5>
                                                <div><Link to={`/product/${match?.params.id}/faq/add`} className="btn btn-theme btn-sm action-btn action-add-button"><i className='fa fa-plus'></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            <div className='faq-content-box'>
                                                <div className="accordion" id="faqAccordion">
                                                    {/* 1 */}
                                                    {Array.isArray(faq) && faq.length ? faq.map((res: any, index: any) => {
                                                        return (
                                                            <div className="accordion-item border-0 mb-3 position-relative" key={index}>
                                                                <h2 className="accordion-header" id="faqOne">
                                                                    <button className="accordion-button shadow-none d-inline-block d-sm-flex align-items-start" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${res._id}`} aria-expanded="true" aria-controls="collapseOne" onClick={() => setFaq(faq.map((res) => { return { ...res, readMore: false } }))}>
                                                                        <b className='me-1 text-nowrap'>Q.</b> {res.question}
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapseOne${res._id}`} className="accordion-collapse collapse " aria-labelledby="faqOne" data-bs-parent="#faqAccordion">
                                                                    <div className="accordion-body border-top-0" >
                                                                        <p className='d-flex'><b className='me-1 text-nowrap text-black'>A.</b><span dangerouslySetInnerHTML={{ __html: res?.answer.length ? !res.readMore ? `${res?.answer.slice(0, 300)}` : res?.answer : '' }} />
                                                                        </p>
                                                                        {res?.answer?.length > 300 ? <p className='ReadMore ps-3' role="button" onClick={() => faqReadMore(index, !res.readMore)}>{!res.readMore ? `${language_data?.[authState.lang]?.common_read_more}` : `${language_data?.[authState.lang]?.common_read_less}`} </p> : ''}
                                                                    </div>
                                                                </div>
                                                                <ul className="edit-icon-accordion d-flex gap-2 gap-md-3 align-items-center mb-0 ps-0 list-unstyled">
                                                                    <li><button className='btn p-0 bg-transparent border-0' onClick={() => deleteSpecificDetail(commonArray.productOtherDetails[3], res._id)}><i className='fa fa-trash fs-5 text-danger'></i></button></li>
                                                                    <li> <Link to={`/product/${match?.params.id}/faq/edit/${res._id}`}><i className='fa fa-pencil fs-5'></i></Link></li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }) : `${language_data?.[authState.lang]?.common_not_available}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
        </Fragment>
    )
}
export default ViewProductDetail;