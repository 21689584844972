import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss';
import profile_img from '../../assets/images/pages/defaultImage.jpg'
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import loginSuccess from '../../context/actions/auth/loginSuccess';
import Spinner from '../../components/common/spinner';
import PhoneInput from 'react-phone-input-2';
import Errormessage from '../../components/common/errormessage';
import Svg from '../../components/common/error-svg';
import BreadCrumb from '../../components/common/BreadCrumb';
import { documentId, validateEmail } from '../../utils/validations';
import Inputs, { SaveCancelButton } from '../../components/common/Inputs';
import Textarea from '../../components/common/Textarea';
import { toast } from 'react-toastify';
import { capitalise, NumberValidation } from "../../utils/validations"

const EditProfile = () => {
    const { language_data, authState, authDispatch, loading, setLoading, toastMessage } = useContext(GlobalContext)

    let breadCrumbPath = [
        { name: 'Home', url: `/`, active: '' },
        { name: 'Profile', url: `/profile`, active: '' },
        { name: 'Edit Profile', url: ``, active: 'not-allowed' },
    ]
    const [nameErr, setNameErr] = useState<string>('')
    const [emailErr, setEmailErr] = useState<string>('')
    const [phoneEr, setPhoneEr] = useState<string>('')
    const [cityErr, setCityErr] = useState<string>('')
    const [stateErr, setStateErr] = useState<string>('')
    const [companyErr, setCompanyErr] = useState<string>('')
    const [pinCodeErr, setPinCodeErr] = useState<string>('')
    const [selectedFile, setSelectedFile] = useState<any>()
    const [fullAddressErr, setFullAddressErr] = useState<string>('')
    const [apartmentNoErr, setApartmentNoErr] = useState<string>('')
    // const phoneErr = useRef(null) as React.MutableRefObject<any>
    const placeInputRef = useRef(null) as React.MutableRefObject<any>
    const [updateProfile, setUpdateProfile] = useState({
        name: authState.name ?? "",
        email: authState.email ?? "",
        phone_number: authState.phone_number ?? "",
        country_code: authState.country_code ?? "",
        company: authState.company ?? "",
        state: authState.state ?? "",
        city: authState.city ?? "",
        pin_code: authState.pin_code ?? "",
        apartment_number: authState.apartment_number ?? "",
        full_address: authState.full_address ?? "",
        image: authState.image ?? "",
    })
    const changeProfileDetails = ({ target }: any) => {
        const val = target.value;
        const name = target.name;
        // if (name === 'apartment_number' && !validateCharNumber(val) && !validateCharNumber(updateProfile.apartment_number)) {
        //     return
        // }
        // if (name === "deliveryTime" && !NumberValidation(value)) return 
        if (name === 'name') setNameErr('')
        if (name === 'email') setEmailErr('')
        if (name === 'company') setCompanyErr('')
        if (name === 'state') setStateErr('')
        if (name === 'city') setCityErr('')
        if (name === 'pin_code' && !NumberValidation(val)) return
        if (name === 'apartment_number') setApartmentNoErr('')
        if (name === 'full_address') setFullAddressErr('')
        setUpdateProfile({
            ...updateProfile,
            [name]: val
        })
    }
    const uploadImage = async (e: any) => {
        try {
            const apiRes = await henceforthApi.Common.do_spaces_file_upload(`file`, e)
            let items = { image: apiRes.data.file_name }
            let res = await henceforthApi.Auth.editProfile(items)
            loginSuccess(res.data)(authDispatch)
        } catch (err) {
            setLoading(false)
            handleError(err, '', authDispatch);
        }

    }
    const fileUpload = async (file: any) => {
        setLoading(true)
        const apiRes = await henceforthApi.Common.do_spaces_file_upload("file", file)

        const data1 = {
            image: apiRes.data.file_name
        };
        await henceforthApi.Auth.editProfile(data1)
            .then((res: any) => {
                setLoading(false);
                loginSuccess(res.data)(authDispatch);
            })
            .catch((err: any) => {
                console.log(err);
                setLoading(false);
            });

    }
    const onKeyDown = (keyEvent: any) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }
    const editProfile = async (e: any) => {
        e.preventDefault();
        const { name, country_code, phone_number, company, city, pin_code, apartment_number, email, full_address, state } = updateProfile;
        if (!name && !phone_number && !company && !city && !pin_code && !full_address && !state) {
            setCityErr(`${language_data?.[authState.lang]?.common_city} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setNameErr(`${language_data?.[authState.lang]?.common_name} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setPhoneEr(`${language_data?.[authState.lang]?.common_phone} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setCompanyErr(`${language_data?.[authState.lang]?.common_company} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setPinCodeErr(`${language_data?.[authState.lang]?.common_pin_code} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            // setApartmentNoErr('Apartment is required')
            setFullAddressErr(`${language_data?.[authState.lang]?.common_full} ${language_data?.[authState.lang]?.common_address} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setStateErr(`${language_data?.[authState.lang]?.common_state} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
        }
        if (!updateProfile.name) { documentId('name'); setNameErr(`${language_data?.[authState.lang]?.common_name} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.name.trim()) { documentId('name'); setNameErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_name}`); return }
        if (!updateProfile.email.trim()) { documentId('email'); setEmailErr(`${language_data?.[authState.lang]?.common_email} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!validateEmail(updateProfile.email)) { documentId('email'); setEmailErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_fill} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_email}`); return }
        if (!updateProfile.phone_number) { documentId('phone'); toast.warn(`${language_data?.[authState.lang]?.common_phone} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); setPhoneEr(`${language_data?.[authState.lang]?.common_phone}${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (updateProfile.phone_number.length < 10 || updateProfile.phone_number.length > 12) { documentId('phone'); toast.warn(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid}${language_data?.[authState.lang]?.common_numbers}`); setPhoneEr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid}${language_data?.[authState.lang]?.common_numbers}`); return }
        if (!updateProfile.company) { documentId('company'); setCompanyErr(`${language_data?.[authState.lang]?.common_company} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.company.trim()) { documentId('company'); setCompanyErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_company}`); return }
        if (!updateProfile.full_address) { documentId('full_address'); setFullAddressErr(`${language_data?.[authState.lang]?.common_full} ${language_data?.[authState.lang]?.common_address} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.full_address.trim()) { documentId('full_address'); setFullAddressErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_full} ${language_data?.[authState.lang]?.common_address}`); return }
        if (!updateProfile.state) { documentId('state'); setStateErr(`${language_data?.[authState.lang]?.common_state} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.state.trim()) { documentId('state'); setStateErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_state}`); return }
        if (!updateProfile.city) { documentId('city'); setCityErr(`${language_data?.[authState.lang]?.common_city} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.city.trim()) { documentId('city'); setCityErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_city}`); return }
        if (!updateProfile.pin_code) { documentId('pin_code'); setPinCodeErr(`${language_data?.[authState.lang]?.common_pin_code} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`); return }
        if (!updateProfile.pin_code.trim()) { documentId('pin_code'); setPinCodeErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_provide} ${language_data?.[authState.lang]?.common_valid} ${language_data?.[authState.lang]?.common_pin_code} `); return }
        // if (!updateProfile.apartment_number) { documentId('name'); setApartmentNoErr("Apartment number is required");return}
        // if (!updateProfile.apartment_number.trim()) { documentId('name'); setApartmentNoErr("Please provide valid apartment number");return}
        setLoading(true)
        // let imageRes = await uploadImage()
        let data = !apartment_number.trim() ? {
            name: name,
            phone_number: phone_number,
            country_code: country_code,
            company: company,
            city: city,
            state: state,
            pin_code: pin_code,
            language: 'ENGLISH',
            full_address: full_address,
        } as any : {
            name: name,
            phone_number: phone_number,
            country_code: country_code,
            company: company,
            city: city,
            state: state,
            pin_code: pin_code,
            apartment_number: apartment_number,
            full_address: full_address,
            language: 'ENGLISH',
        } as any
        if (!authState.email_verified) data['email'] = email;
        if (selectedFile) {
            if (selectedFile) {
                const image = await fileUpload(selectedFile)
                data.image = image
            }
        }
        // image: imageRes
        // let verify =
        //     authState.name === name && authState.email === email && authState.phone_number === phone_number && authState.country_code === country_code &&
        //     authState.company === company && authState.city === city && authState.state === state &&
        //     authState.pin_code === pin_code &&
        //     authState.full_address === full_address 
        // console.log(verify);

        // if(verify){ window.history.back(); return}
        try {
            let res = await henceforthApi.Auth.editProfile(data)
            loginSuccess(res.data)(authDispatch)
            toastMessage(res.message ? res.message : `${language_data?.[authState.lang]?.common_success}`);
            window.history.back()
        }
        catch (err) {
            handleError(err, 'active', authDispatch);

        } finally {
            setLoading(false)
        }
    }
    // const changeProfilePicture = async (e: any) => {
    //     let file = e.target.files[0]
    //     setFile(file)
    // }
    const phoneCheck = (e: any, v: any) => {
        setPhoneEr('')
        setUpdateProfile({
            ...updateProfile,
            phone_number: e.phone.slice(Number(v.dialCode.length), Number(e.phone.length)),
            country_code: v.dialCode,
        });
    }
    function loadGoogleMapScript(callback: any) {
        // console.log('loadGoogleMapScript');

        if (
            typeof (window as any).google === "object" &&
            typeof (window as any).google.maps === "object"
        ) {
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1CuR295O6gnkuW1tHdPMjqFpQewhAsD4&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener("load", callback);
        }
    }
    // console.log(placeInputRef.current.value);

    const initPlaceAPI = () => {
        loadGoogleMapScript(() => {
            if (placeInputRef) {
                let autocomplete = new (window as any).google.maps.places.Autocomplete(
                    placeInputRef.current
                );
                new (window as any).google.maps.event.addListener(
                    autocomplete,
                    "place_changed",
                    () => {
                        let place = autocomplete.getPlace();
                        let formatAddress = place.formatted_address
                        const address = place.address_components
                        let items: any = {}
                        if (Array.isArray(address) && address.length > 0) {
                            let zipIndex = address.findIndex(res => res.types.includes("postal_code"))
                            let administrativeAreaIndex = address.findIndex(res => res.types.includes("administrative_area_level_1", "political"))
                            let localityIndex = address.findIndex(res => res.types.includes("locality", "political"))
                            let countryIndex = address.findIndex(res => res.types.includes("country", "political"))
                            let premiseIndex = address.findIndex(res => res.types.includes("premise", "street_number"))

                            if (zipIndex > -1) {
                                items.pin_code = address[zipIndex].long_name
                            }
                            if (administrativeAreaIndex > -1) {
                                items.state = address[administrativeAreaIndex].long_name
                            }
                            if (localityIndex > -1) {
                                items.city = address[localityIndex].long_name
                            }
                            if (countryIndex > -1) {
                                items.country = address[countryIndex].long_name
                            }
                            if (premiseIndex > -1) {
                                items.apartment_number = address[premiseIndex].long_name
                            }
                            items.full_address = formatAddress
                            setUpdateProfile((updateProfile) => {
                                return {
                                    ...updateProfile,
                                    city: items?.city ? items?.city : "",
                                    state: items?.state ? items?.state : "",
                                    pin_code: items?.pin_code ? items?.pin_code : "",
                                    apartment_number: items?.apartment_number ? items?.apartment_number : "",
                                    full_address: items?.full_address ? items?.full_address : "",
                                }
                            })
                            setCityErr('')
                            setStateErr('')
                            setPinCodeErr('')
                            setApartmentNoErr('')
                            setFullAddressErr('')
                        }

                    }
                );
            }

        });
    };

    useEffect(() => {
        initPlaceAPI()
    }, [])
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-9 col-md-5 col-lg-4 col-xl-4 col-xxl-3 px-0">
                                {/* title  */}
                                <div className="common-card h-100">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_profile}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        {/* Upload image */}
                                        <form onKeyDown={onKeyDown} onSubmit={editProfile}>
                                            <div className='upload-fields-box mb-3'>
                                                <div className='profile-edit-image mb-2'>
                                                    <div className='profile-edit-upload'>
                                                        <input type="file" onChange={(e: any) => setSelectedFile(e.target.files[0])} accept="image/*" />
                                                    </div>
                                                    {/* <img src={selectedFile ? URL?.createObjectURL(selectedFile) : authState?.image ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState?.image}` : profile_img} alt="img" className='rounded-circle' /> */}

                                                    <img src={selectedFile ? URL?.createObjectURL(selectedFile) : authState.image ? authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState?.image}` : `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState.image}` : profile_img} alt={authState.image} className='rounded-circle' />
                                                </div>
                                                <p className='text-center'><small><strong>{language_data?.[authState.lang]?.common_note}:-</strong> {language_data?.[authState.lang]?.common_please_upload_only_jpg_and_png_format_only}.</small></p>
                                            </div>

                                            {/* Name */}
                                            <Inputs.Input type="text" id="name" label={`${language_data?.[authState.lang]?.common_name}`} error={nameErr} value={updateProfile.name as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_name}`} name='name' handleChange={changeProfileDetails} />
                                            {/* Email */}
                                            <Inputs.Input type="text" id="email" label={`${language_data?.[authState.lang]?.common_email}`} value={updateProfile.email as any} error={emailErr} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_email}`} name='email' handleChange={changeProfileDetails} disabled={authState.email_verified} email_verified={authState.email_verified} />
                                            {/* Phone */}
                                            <div className={`form-fields-box mb-2 rounded-0 ${phoneEr ? 'border border-danger position-relative' : ''}`} id="phone">
                                                <label className='fw-bolder mb-1'>{language_data?.[authState.lang]?.common_phone} {language_data?.[authState.lang]?.common_no}.</label>
                                                <PhoneInput country={'in'} value={updateProfile.country_code.concat(updateProfile.phone_number)} inputProps={{ required: true }} onChange={(phone: any, value: any) => { phoneCheck({ phone }, value) }} />
                                                {phoneEr ? <span className='svg-question'>
                                                    <Svg /></span> : ''}
                                                {/* {phoneEr ? "" : <p ref={phoneErr} className="text-danger"></p>} */}
                                            </div>
                                            <Errormessage phone={phoneEr} error={phoneEr} />
                                            {/* Company */}
                                            <Inputs.Input type="text" id="company" label={`${language_data?.[authState.lang]?.common_company}${language_data?.[authState.lang]?.common_name}`} error={companyErr} value={updateProfile.company as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_company}`} name='company' handleChange={changeProfileDetails} />
                                            {/* Address search */}
                                            <Inputs.InputRef type="text" label={`${language_data?.[authState.lang]?.common_search}${language_data?.[authState.lang]?.common_location}`} ref={placeInputRef} />
                                            {/* Address */}
                                            <Textarea error={fullAddressErr} id="full_address" label={`${language_data?.[authState.lang]?.common_full}${language_data?.[authState.lang]?.common_address}`} col={10} row={5} name='full_address' placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_full}${language_data?.[authState.lang]?.common_address}`} value={updateProfile.full_address as any} handleChange={changeProfileDetails} />
                                            {/* State */}
                                            <Inputs.Input type="text" id="state" label={`${language_data?.[authState.lang]?.common_state}`} error={stateErr} value={updateProfile.state as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_state}`} name='state' handleChange={changeProfileDetails} />
                                            {/* City */}
                                            <Inputs.Input type="text" id="city" label={`${language_data?.[authState.lang]?.common_state}`} error={cityErr} value={updateProfile.city as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_city}`} name='city' handleChange={changeProfileDetails} />
                                            {/* PinCode */}
                                            <Inputs.Input type="text" maxLength={6} id="pin_code" label={`${language_data?.[authState.lang]?.common_pin_code}`} error={pinCodeErr} value={updateProfile.pin_code as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your}${language_data?.[authState.lang]?.common_pin_code}`} name='pin_code' handleChange={changeProfileDetails} />
                                            {/* Appartment no. */}
                                            {/* onChange={(e) => {
                                                NumberValidation(e.target.value) ? setMin_price(e.target.value) : console.log(e.target.value)
                                            }} */}
                                            <Inputs.Input type="text" label={`${language_data?.[authState.lang]?.common_Apartment} ${language_data?.[authState.lang]?.common_number}`} error={apartmentNoErr} value={updateProfile.apartment_number as any} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_Apartment} ${language_data?.[authState.lang]?.common_number}`} name='apartment_number' handleChange={changeProfileDetails} />
                                            {/* Buttons. */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default EditProfile;