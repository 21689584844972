import '../../assets/styles/auth.scss'
import logo from '../../assets/images/logo/logo.png'
import { useNavigate } from 'react-router-dom';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import Spinner from '../../components/common/spinner';
import { NumberValidation } from '../../utils/validations';
const OtpVerification = () => {
  const {language_data, authState,loading, setLoading, toastMessage,authDispatch } = useContext(GlobalContext)
  const navigate = useNavigate();
  const [timerCount, setTimerCount] = useState(0)
  const [otpErr, setOtpErr] = useState('')
  const [input, setInput] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: ""
  })
  function onhandleChange(evt: any) {
    const value = evt.target.value;
    if (NumberValidation(value)) {
      setOtpErr('')
      setInput({
        ...input,
        [evt.target.name]: value
      });
      Onfocus(evt.target.value)
    }
  }

  function onhandleChange1(evt: any) {
    const value = evt.target.value;
    if (NumberValidation(value)) {
      setOtpErr('')
      setInput({
        ...input,
        [evt.target.name]: value
      });
      Onfocus1(evt.target.value)
    }
  }

  function onhandleChange2(evt: any) {
    const value = evt.target.value;
    if (NumberValidation(value)) {
      setOtpErr('')
      setInput({
        ...input,
        [evt.target.name]: value
      });
      Onfocus2(evt.target.value)
    }
  }

  function onhandleChange3(evt: any) {
    const value = evt.target.value;
    if (NumberValidation(value)) {
      setOtpErr('')
      setInput({
        ...input,
        [evt.target.name]: value
      });
      Onfocus3(evt.target.value)
    }
  }

  let inputRef4 = useRef(null) as React.MutableRefObject<any>
  let inputRef5 = useRef(null) as React.MutableRefObject<any>
  let inputRef6 = useRef(null) as React.MutableRefObject<any>
  let inputRef7 = useRef(null) as React.MutableRefObject<any>

  const Onfocus = (value: string) => {
    if (value) {
      inputRef5.current.focus()
    }
  }
  const Onfocus1 = (value: string) => {
    if (value) {
      inputRef6.current.focus()
    }
    else {
      inputRef4.current.focus()
    }
  }
  const Onfocus2 = (value: string) => {
    if (value) {
      inputRef7.current.focus()
    }
    else {
      inputRef5.current.focus()
    }
  }
  const Onfocus3 = (value: string) => {
    if (value) {
      inputRef7.current.focus()
    }
    else {
      inputRef6.current.focus()
    }
  }


  // console.log(input);
  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("unicode")
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log(term);
    const { input1, input2, input3, input4 } = input;
    // console.log(input);
    setLoading(true)
    try {
      let otpRes = await henceforthApi.Auth.checkOtp({
        unique_code: term,
        otp: `${input1}${input2}${input3}${input4}`,
        language: "ENGLISH"
      })
      toastMessage(otpRes.data.message)
      navigate({
        pathname: '/password/reset',
        search: `?unicode=${otpRes.data.unique_code}`,
      })
      setInput({
        ...input,
        input1: "",
        input2: "",
        input3: "",
        input4: ""
      })
      inputRef4.current.focus()
    } catch (err) {
      setInput({
        ...input,
        input1: "",
        input2: "",
        input3: "",
        input4: ""
      })
      setOtpErr('error')
      handleError(err,'active',authDispatch);
      inputRef4.current.focus()
    }finally{
      setLoading(false)
    }
  }
  var countdownTimer = null as any;
  const initialiseInterval = () => {
    var _second = 30
    function timer() {
      if (_second < 1) {
        clearInterval(countdownTimer);
        countdownTimer = null
        return;
      } else {
        _second -= 1
      }
      setTimerCount(_second)
    }
    countdownTimer = setInterval(() => timer(), 1000);
  }
  useEffect(() => { initialiseInterval() }, [])
  const resendOtp = async () => {
    try {
      let res = await henceforthApi.Auth.resendOtp({
        unique_code: term,
        language: "ENGLISH"
      })
      toastMessage(res.data.message)
      initialiseInterval()
    } catch (err) {
      handleError(err,'active',authDispatch);
    }
  }
  return (
    <Fragment>
      <section className='auth-pages'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-10">
              <div className="auth-page-card mb-4">
                {/* logo */}
                <div className='auth-logo-box text-center'>
                  <img src={logo} alt="img" />
                </div>
                {/* title  */}
                <div className='auth-page-card-title text-center my-4'>
                  <h3 className='mb-2'>{language_data?.[authState.lang]?.common_otp} {language_data?.[authState.lang]?.common_verification}.</h3>
                </div>
                {/* form  */}
                <form onSubmit={handleSubmit}>
                  {/* Password  */}
                  <div className='form-fields-box mb-3 d-flex gap-3 otp-verification-inputs justify-content-center'>
                    <input
                      type="text"
                      className={`form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                      placeholder=""
                      tabIndex={1}
                      name='input1'
                      maxLength={1}
                      ref={inputRef4}
                      value={input.input1}
                      onChange={onhandleChange}
                      required />
                    <input
                      type="text"
                      className={`form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                      ref={inputRef5}
                      tabIndex={2}
                      name='input2'
                      value={input.input2}
                      maxLength={1}
                      onChange={onhandleChange1}
                      placeholder=""
                      required />
                    <input
                      type="text"
                      className={`form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                      ref={inputRef6}
                      tabIndex={3}
                      name='input3'
                      value={input.input3}
                      maxLength={1}
                      onChange={onhandleChange2}
                      placeholder=""
                      required />
                    <input
                      type="text"
                      className={`form-control rounded-0 ${otpErr ? 'border border-danger' : ''}`}
                      tabIndex={4}
                      ref={inputRef7}
                      name='input4'
                      value={input.input4}
                      maxLength={1}
                      onChange={onhandleChange3}
                      placeholder=""
                      required />
                  </div>
                  {/* Submit button  */}
                  <div className='form-fields-box mb-3'>
                    <button type='submit' className='btn btn-theme w-100' disabled={loading}>
                      {loading ? <Spinner color={"text-white"} /> : `${language_data?.[authState.lang]?.common_submit}`}
                    </button>
                  </div>
                  {/* Not Receive OTP  */}
                  <div className="auth-form-links text-center">
                    <p>{language_data?.[authState.lang]?.common_did_not} {language_data?.[authState.lang]?.common_recieve} {language_data?.[authState.lang]?.common_otp}{language_data?.[authState.lang]?.common_questionmark}<button type="button" className={`btn border-0 bg-transparent border-0${timerCount !== 0 ? 'text-muted text-decoration-none' : ''}`} disabled={timerCount !== 0} onClick={resendOtp}>{timerCount === 0 ? "Resend OTP" : `Resend OTP in ${timerCount}`}</button></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </section >
    </Fragment>
  );
};

export default OtpVerification;
