import '../../assets/styles/pages.scss'
import '../../assets/styles/auth.scss'
import BreadCrumb from '../../components/common/BreadCrumb'
import { Fragment, useContext, useEffect, useState } from 'react'
import { coupons } from './couponInterface'
import henceforthApi from '../../utils/henceforthApi'
import { GlobalContext, handleError } from '../../context/Provider'
import Spinner from '../../components/common/spinner'
import { toast } from 'react-toastify'
import moment from 'moment'
const AddCoupon = () => {
    const {language_data, authState, loading, setLoading, authDispatch } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_coupon}  ${language_data?.[authState.lang]?.common_list}`, url: `/coupons/1`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_add}  ${language_data?.[authState.lang]?.common_coupon}`, url: ``, active: 'not-allowed' },
    ]
    
    const [coupon, setCoupon] = useState({
        couponName: '',
        description: '',
        type: '',
        subType: '',
        price: 0,
        maxDiscount: 0,
        percentage: '',
        startDate: '',
        endDate: '',
        applicable_for: "ALL",
        product_ids: [],
        language: "ENGLISH"
    } as coupons)
    const [searchProduct, setSearchProduct] = useState<string>('')
    const [load, setLoad] = useState<boolean>(false)
    const [productList, setProductList] = useState<{ _id: string, name: string }[]>([])
    const [couponNameErr, setCouponNameErr] = useState<string>('')
    const [descriptionErr, setDescriptionErr] = useState<string>('')
    const [typeErr, setTypeNameErr] = useState<string>('')
    const [subTypeErr, setsubTypeNameErr] = useState<string>('')
    const [priceErr, setPriceErr] = useState<string>('')
    const [maxDiscountErr, setMaxDiscountErr] = useState<string>('')
    const [percentageErr, setPercentageErr] = useState<string>('')
    const [startDateErr, setStartDateErr] = useState<string>('')
    const [endDateErr, setEndDateErr] = useState<string>('')
    const handleChange = (e: any) => {
        let name = e.target.name
        let value = e.target.value
        if (name === 'price' && String(value).startsWith('0')) return
        if (name === 'maxDiscount' && String(value).startsWith('0')) return
        if (name === 'percentage' && value.startsWith('0')) return
        if (name === 'price' && isNaN(value) && !value.includes('.') && !String(value).startsWith('0')) return
        if (name === 'maxDiscount' && isNaN(value)) return
        if (name === 'percentage' && isNaN(value)) return
        if (name === 'type' && value) {
            setPriceErr('')
            setMaxDiscountErr('')
            setPercentageErr('')
        }
        if (name === 'startDate' && value === '' || coupon.startDate) {
            setCoupon((coupon: any) => {
                return {
                    ...coupon,
                    endDate: ''
                }
            })
        }
        setCoupon((coupon: any) => {
            return {
                ...coupon,
                [name]: value
            }
        })
    }

    const productListing = async () => {
        setLoad(true)
        try {
            let res = (await henceforthApi.Product.getProductList(searchProduct, '', '', '')).data.data
            setProductList(res)
        } catch (err) {
            console.log("err", err);
            handleError(err, '', authDispatch)
        } finally {
            setLoad(false)
        }
    }
    useEffect(() => {
        productListing();
    }, [searchProduct])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!coupon.couponName.trim() && !coupon.description && !coupon.type.trim() && !coupon.subType.trim() && !coupon.startDate.trim() && !coupon.endDate.trim()) {
            setCouponNameErr('a')
            setTypeNameErr('a')
            setsubTypeNameErr('a')
            // setPriceErr('a')
            // setMaxDiscountErr('a')
            setStartDateErr('a')
            // setPercentageErr('a')
            setEndDateErr('a')
            setDescriptionErr('a')
            return
        }
        if (!coupon.couponName.trim()) return setCouponNameErr('a')
        if (!coupon.description.trim()) return setDescriptionErr('a')
        if (!coupon.type.trim()) return setTypeNameErr('a')
        if (!coupon.subType.trim()) return setsubTypeNameErr('a')
        if (coupon.type === 'PERCENTAGE') {
            if (coupon.maxDiscount === 0 && !coupon.maxDiscount && !coupon.percentage.trim()) {
                setPercentageErr('a')
                setMaxDiscountErr('a')
                return
            }
            if (coupon.maxDiscount === 0) return setMaxDiscountErr('a')
            if (!coupon.maxDiscount) return setMaxDiscountErr('a')
            // if (coupon.maxDiscount >= 100) return setMaxDiscountErr('a')
            if (!coupon.percentage.trim()) return setPercentageErr('a')
            if (Number(coupon.percentage.trim()) === 0) return setPercentageErr('a')
            if (Number(coupon.percentage.trim()) >= 101) return setPercentageErr('a')
        } else if (coupon.type === 'FIXED') {
            if (coupon.price === 0) return setPriceErr('a')
            if (!coupon.price) return setPriceErr('a')
        }
        if (!coupon.startDate) return setStartDateErr('a')
        if (!coupon.endDate) return setEndDateErr('a')
        let ids = coupon.product_ids.map((res) => res._id)
        let items = coupon.type === 'FIXED' ? {
            name: coupon.couponName,
            description: coupon.description,
            type: coupon.type,
            sub_type: coupon.subType,
            start_date: coupon.startDate,
            end_date: coupon.endDate,
            price: coupon.price,
            applicable_for: "ALL",
            product_ids: ids.length ? ids : [],
            language: "ENGLISH"
        } : {
            name: coupon.couponName,
            description: coupon.description,
            type: coupon.type,
            sub_type: coupon.subType,
            start_date: coupon.startDate,
            end_date: coupon.endDate,
            percentage: coupon.percentage,
            max_discount: coupon.maxDiscount,
            applicable_for: ids.length ? "LIMITED" : "ALL",
            product_ids: ids,
            language: "ENGLISH"
        }
        setLoading(true)
        try {
            let apiRes = await henceforthApi.Coupons.addCoupon(items)
            toast.success(apiRes.message)
            window.history.back()
        } catch (err) {
            handleError(err, 'active', authDispatch)
        } finally {
            setLoading(false)
        }
    }
    const product_idsHandler = (id: string, name: string, index: number) => {
        if (id && name) {
            let temp2 = coupon.product_ids.some((res) => res._id === id && res.name === name)
            // let temp = coupon.product_ids.filter((res) => res._id !== id && res.name !== name)
            if (temp2) return toast.warn(`${language_data?.[authState.lang]?.common_already_} ${language_data?.[authState.lang]?.common_added_}`)
            setCoupon({
                ...coupon,
                product_ids: [...coupon.product_ids, { _id: id, name: name }]
            })
            setSearchProduct('')
        }
        else {
            coupon.product_ids.splice(index, 1)
            setCoupon((coupon) => { return { ...coupon } })
        }
    }

    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common-card">
                                    <div className="common-card-content">
                                        <div className="form-fields-box">
                                            <label className="mb-1 form-label fw-semibold"><b>{language_data?.[authState.lang]?.common_search} {language_data?.[authState.lang]?.common_product}</b> ({language_data?.[authState.lang]?.common_coupon_valid_for_selected_product})</label>
                                            <div className="position-relative">
                                                {/* <form action=""> */}
                                                <input type="search" className="form-control rounded-0 ps-4 " name="search" placeholder={`${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_your} ${language_data?.[authState.lang]?.common_product}`} value={searchProduct} onChange={({ target }: any) => { setLoad(true); setSearchProduct(target.value) }} />
                                                <span className="search-icon">
                                                    <i className="fa fa-search"></i>
                                                </span>

                                                {searchProduct && Array.isArray(productList) && !load && productList.length ? <ul className='mb-0 list-unstyled search-coupon-list'>
                                                    {productList.map((res) => <li key={res._id} onClick={() => product_idsHandler(res._id, res.name, -1)}><p>{res?.name}</p></li>)}
                                                </ul> : ''}
                                                {/* </form> */}
                                            </div>
                                            {/* coupon-list-box */}
                                            {Array.isArray(coupon.product_ids) && coupon.product_ids.length ? <div className="coupon-list-box mt-3 d-inline-flex gap-3">
                                                {coupon.product_ids.map((res, index) => <span className='coupon-list-badge' key={res._id} >{res.name} <i className='fa fa-close ms-1' role="button" onClick={() => product_idsHandler('', '', index)}></i></span>)}
                                            </div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {/* Title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_coupon}</h5>
                                    </div>
                                    <div className="common-card-content">
                                        {/* form  */}
                                        <form onSubmit={handleSubmit}>
                                            <div className="table-responsive data-list-table table-responsive mb-3'">
                                                {/* Table  */}
                                                <table className="table table table-striped align-middle coupon-table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_coupon} {language_data?.[authState.lang]?.common_name}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_description}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_type}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_sub_type}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_price}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_max} {language_data?.[authState.lang]?.common_discount}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_percentage}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_start_date}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_end_date}
                                                            </th>
                                                            <th>
                                                            {language_data?.[authState.lang]?.common_action}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='form-fields-box'>
                                                                <input type="text" className={`form-control rounded-0 ${couponNameErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?.common_coupon} ${language_data?.[authState.lang]?.common_name}`} value={coupon.couponName} name='couponName' onChange={(e) => { setCouponNameErr(''); handleChange(e) }} />
                                                                {/* <Errormessage class={couponNameErr} error={couponNameErr} /> */}

                                                            </td>
                                                            <td className='form-fields-box'>
                                                                <input type="text" className={`form-control rounded-0 ${descriptionErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?.common_description}`} value={coupon.description} name='description' onChange={(e) => { setDescriptionErr(''); handleChange(e) }} />
                                                                {/* <Errormessage class={couponNameErr} error={couponNameErr} /> */}

                                                            </td>
                                                            <td className='form-select-box coupon-box'>
                                                                <select className={`form-control rounded-0 px-1 ${typeErr ? 'is-invalid' : ''}`} value={coupon.type} name="type" onChange={(e) => { setTypeNameErr(''); handleChange(e) }} >
                                                                    <option value=''>{language_data?.[authState.lang]?.common_select} {language_data?.[authState.lang]?.common_type}</option>
                                                                    <option value='FIXED'>{language_data?.[authState.lang]?.common_fixed} {language_data?.[authState.lang]?.common_price}</option>
                                                                    <option value='PERCENTAGE'>{language_data?.[authState.lang]?.common_percentage}</option>

                                                                </select>
                                                                {/* <Errormessage class={typeErr} error={typeErr} /> */}

                                                            </td>
                                                            <td className='form-select-box coupon-box '>
                                                                <select className={`form-control rounded-0 px-1 ${subTypeErr ? 'is-invalid' : ''}`} value={coupon.subType} name='subType' onChange={(e) => { setsubTypeNameErr(''); handleChange(e) }}>
                                                                    <option value=''>{language_data?.[authState.lang]?.common_select} {language_data?.[authState.lang]?.common_sub_type}</option>
                                                                    <option value='ONE_TIME'>{language_data?.[authState.lang]?.common_one} {language_data?.[authState.lang]?.common_time} {language_data?.[authState.lang]?.common_coupon}</option>
                                                                </select>
                                                                {/* <Errormessage class={subTypeErr} error={subTypeErr} /> */}
                                                            </td>
                                                            <td className='form-fields-box'>
                                                                <input type="text" className={`form-control rounded-0 ${priceErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?._1} ${language_data?.[authState.lang]?._0}`} name='price' value={coupon.price === 0 ? '' : coupon.price} onChange={(e) => { setPriceErr(''); handleChange(e) }} disabled={!coupon.type || coupon.type === "PERCENTAGE"} />
                                                                {/* <Errormessage class={priceErr} error={priceErr} /> */}
                                                            </td>
                                                            <td className='form-fields-box'>
                                                                <input type="text" className={`form-control rounded-0 ${maxDiscountErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?._1} ${language_data?.[authState.lang]?._0}`} name='maxDiscount' value={coupon.maxDiscount === 0 ? '' : coupon.maxDiscount} onChange={(e) => { setMaxDiscountErr(''); handleChange(e) }} disabled={!coupon.type || coupon.type === "FIXED"} />
                                                                {/* <Errormessage class={maxDiscountErr} error={maxDiscountErr} /> */}
                                                            </td>
                                                            <td className='form-fields-box'>
                                                                <input type="text" className={`form-control rounded-0 ${percentageErr ? 'is-invalid' : ''}`} placeholder={`${language_data?.[authState.lang]?._1} ${language_data?.[authState.lang]?._0}`} name='percentage' value={coupon.percentage} maxLength={3} onChange={(e) => { setPercentageErr(''); handleChange(e) }} disabled={!coupon.type || coupon.type === "FIXED"} />
                                                                {/* <Errormessage class={maxDiscountErr} error={maxDiscountErr} /> */}
                                                            </td>
                                                            <td className='form-fields-box '>
                                                                <input type="date" className={`form-control rounded-0 ${startDateErr ? 'is-invalid' : ''}`} name='startDate' min={new Date().toISOString().split('T')[0]} value={coupon.startDate} onChange={(e) => { setStartDateErr(''); handleChange(e) }} />
                                                                {/* <Errormessage class={startDateErr} error={startDateErr} /> */}
                                                            </td>
                                                            <td className='form-fields-box '>
                                                                <input type="date" className={`form-control rounded-0 ${endDateErr ? 'is-invalid' : ''}`} name='endDate' min={moment(coupon.startDate).add(1, 'days').format('YYYY-MM-DD')} value={coupon.endDate} disabled={!coupon.startDate} onChange={(e) => { setEndDateErr(''); handleChange(e) }} />
                                                                {/* <Errormessage class={endDateErr} error={endDateErr} /> */}
                                                            </td>
                                                            <td>
                                                                <button type="submit" className="btn btn-white btn-sm " disabled={loading}>{loading ? <Spinner color='text-success' /> : <><i className="fa fa-save me-1"></i> {language_data?.[authState.lang]?.common_save}</>}</button>
                                                            </td>
                                                        </tr>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default AddCoupon;