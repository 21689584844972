import moment from "moment"
import { Fragment, useContext } from "react"
import { Link } from "react-router-dom"
import { GlobalContext, handleError } from "../../context/Provider"
import henceforthApi from "../../utils/henceforthApi"

export default (props: any) => {
    const {language_data, authDispatch, authState, loading, setLoading } = useContext(GlobalContext)

    const markAsRead = async (id: string) => {
        if (loading) return
        if (props.readNotification) return
        setLoading(true)
        props.getMessagesApi()
        try {
            await henceforthApi.Notification.markAsRead({}, id)
        } catch (error) {
            handleError(error, '', '')
        } finally {
            setLoading(false)
        }
    }
    return <>
        {props.checkLength ? <li className="p-2 pb-0">{props.NotificationHeading}</li> : ''}
        {Array.isArray(props.Array) && props.Array.length ? props.Array.slice(0, 10).map((res: any, index: number) => <Fragment key={index}><li className='px-3 py-2'>
            <Link to={`/order/${res?.orderProduct_id}`} className="dropdown-item p-2" onClick={() => { markAsRead(res._id) }}>
                <div className="media-body notification-text d-flex justify-content-between align-items-start gap-2">
                    <div className="d-flex gap-2 align-items-center notification-detail"> <i className="fa fa-envelope fa-fw"></i><p dangerouslySetInnerHTML={{ __html: res?.message }} ></p>
                        <p></p></div>
                    <p className="pull-right text-muted small">{moment(Number(res.created_at)).fromNow()} </p>
                </div>
            </Link>
        </li><li className="divider mx-2 my-0"></li></Fragment>) : props.Notifications ? <li className="mx-2 my-0 py-3 notification-text text-center"><p className="text-grey">{props.Notifications}</p></li> : ''}
        {props.viewAll ? <li className="text-center"><p>{language_data?.[authState.lang]?.common_view} {language_data?.[authState.lang]?.common_all}</p></li> : ''}
    </>
}